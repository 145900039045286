@import "../../../assets/scss/variables";

.tabs {
    box-shadow: none;
    cursor: pointer;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 100%;
    display: block !important;

    @media (max-width: 767px) {
        overflow-x: scroll;
    }

    >li {
        border-bottom: 4px solid transparent;
        color: $dark_text_color;
        padding: 16px 3.31% 8px;
        // white-space: nowrap;
        display: inline-block;

        &.selected {
            border-bottom-color: $color_primary;
            color: $color_primary;
        }
    }
}

@media (max-width: 768px) {

    .tabs {

        li {
            font-size: 12px;
        }
    }
}

@media (max-width: 480px) {

    .tabs {
        justify-content: space-between;
        margin: 0 -10px;
        width: auto;

        li {
            padding: 16px 8px 8px;
        }
    }
}