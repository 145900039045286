@import "../../../assets/scss/variables";

.label {
    border-radius: 20px;
    display: inline-flex;
    font-size: 12px;
    height: 20px;
    padding: 1px 9px;
    width: fit-content;

    &.warning {
        background-color: $color_warning_light;
        color: $color_warning;
    }

    svg {
        margin-bottom: 2px;
        margin-right: 3px;
    }

    &.active {
        background-color: $color_primary_light;
        color: $color_primary_dark;
    }
}
