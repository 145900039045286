@import "../../assets/scss/variables";

.label {
    color: $dark_text_color;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 8px;
}

.form-input-group {
    padding-top: 48px;
}

.social-btn-group {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 24px;
    margin-left: -5px;
    margin-right: -5px;

    .form-row {

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.signup-error {
    margin-bottom: 10px;
}