@import "../../../assets/scss/variables";

.input {
    appearance: none;
    background-color: #fff;
    border: 1px solid #8996a2;
    border-radius: $base_border_radius * 2;
    color: $light_text_color;
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    padding: 8px 16px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
        border: 1px solid #8996a2;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
        border-color: $color_primary;
    }
}
