@import "../../../assets/scss/variables";

.card {

    &-heading {
        color: $dark_text_color;
        font-size: 28px;
        font-weight: 300;
        line-height: 1.27;
    }

    &-subtitle {
        margin-bottom: 7px;
        text-align: center;
    }
}


@media (max-width: 767px) {

    .card {

        &.with-icon {
            flex-direction: row;
            padding: 8px 6px 0;
        }
    }
}

@media (max-width: 480px) {

    .card {

        &-heading {
            font-size: 24px;
            padding-bottom: 4px;
        }

        &-subtitle {
            line-height: 1.2;
            margin-bottom: 10px;
        }

        &.with-icon {
            flex-direction: row;
            padding: 8px 6px 0;

            .card-heading {

                svg {
                    height: 24px;
                    margin-right: 8px;
                    width: 24px;
                }
            }

            .card-subtitle {
                margin-bottom: 0;
                padding-right: 18px;
            }
        }
    }
}