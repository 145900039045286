@import "../../../assets/scss/variables";

.chart-legend {
    color: #666;
}

.legend-dot {
    background-color: $light_text_color;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-right: 8px;
    width: 6px;
}

.active {

    .legend-dot {
        background-color: $color_primary;
    }
}
