@import "../../../assets/scss/variables";

.container {
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-bottom: 12px;
    padding-left: 32px;
    position: relative;
    user-select: none;

    input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
    }
}

.checkmark {
    background-color: transparent;
    border: 1px solid #8996a2;
    border-radius: 50%;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;

    &:after {
        content: "";
        display: none;
        position: absolute;
    }
}

.container {

    &:hover input ~ .checkmark {
        background-color: #f3f3f3;
    }

    input:checked {

        ~ .checkmark {
            background-color: $color_primary;
            border-color: $color_primary;

            &:after {
                display: block;
            }
        }
    }
}

.input-error {
    border-color: $color_error;
    color: $color_error;
}

.error-block {
    color: $color_error;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}
