@import "../../../../assets/scss/variables";

.nav-menu {

    a:not(.get-started-link) {
        color: $medium_text_color;
        font-size: 14px;
    }

    .list-item:not(:last-child) {
        margin-right: 40px;
    }
}

.get-started-link {
    background-color: $color_primary;
    border: 1px solid $color_primary;
    border-radius: $base_border_radius * 2;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    color: #fff;
    padding: 7.3px 16px;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    transition: all 0.2s linear;

    &:hover {
        background-color: $color_primary_hover;
        border-color: $color_primary_hover;
        color: #fff;
        text-decoration: none;
    }
}

.noOutline {
    background-color: transparent !important;
    color: #36aca3 !important;
    &:focus {
        color: #fff !important;
        background-color: #36aca3 !important;
    }
    &:hover {
        color: #fff !important;
        background-color: #36aca3 !important;
    }
}

@media (max-width: 920px) {

    .nav-menu {
        background-color: #fff;
        box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
        flex-direction: column;
        height: fit-content;
        justify-content: flex-start;
        max-width: 414px;
        padding: 0;
        position: absolute;
        right: -9px;
        top: 32px;
        width: 100vw;
        z-index: 9;

        .list-item {
            padding: 8px 16px;
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }

    .get-started-link {
        background-color: #fff;
        border: 0;
        box-shadow: none;
        color: $color_primary;
        padding: 0;
        text-decoration: underline;
        white-space: nowrap;
    }
}


@media (max-width: 767px) {
    .nav-menu {
        .get-started-link {
            &:hover {
                background-color: transparent;
                border: none;
                color: $medium_text_color;
                font-weight: 400;
            }
        }
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .nav-menu {
        max-width: 340px;
        top: 42px;
    }
}

@media (max-width: 360px) {
    .nav-menu {
        max-width: 290px;
        top: 36px;
    }
}