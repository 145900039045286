.section-fixed-top {
    padding-top: 60px;
    max-width: 100%;
    /* max-width: 1280px; */
    /* margin: auto; */
    margin: 0 15px;
}

.section-wrapper {
    max-width: 100%;
    /* max-width: 1280px; */
    margin: auto;
    /* padding: 0 15px; */
}

.section-wrapper>div {
    overflow: inherit !important;
}

.forgot-password h1 {
    margin-top: 18.760px;
    margin-bottom: 6px;
}

.signin-section h1 {
    margin-bottom: 6px;

}

.text-middle {
    text-align: center;
}

.text-middle a {
    color: #36aca3 !important;
}

@media (max-width: 767px) {
    .section-fixed-top {
        max-width: 100%;
        position: relative;
        padding-top: 0;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .section-fixed-top {
        max-width: 100%;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .section-fixed-top {
        max-width: 100%;
        /* max-width: 984px; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 984px; */
    }
}

@media (min-width: 1330px) {
    .section-fixed-top {
        max-width: 100%;
        /* max-width: 1280px; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 1280px; */
    }
}

@media (max-width: 1380px) and (min-width: 1025px) {
    .section-fixed-top {
        max-width: 100%;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .section-fixed-top {
        padding-top: 60px;
        max-width: 100%;
        /* max-width: 1280px; */
        /* margin: auto; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 1280px; */
    }
}


/* .selected-plan-form form {
    padding-top: 4px;
} */
/* .section-fixed-top.signin-section {
    padding-top: 30px;
}*/
/* 
.section-fixed-top.signup-section {
    padding-top: 30px;
}  */