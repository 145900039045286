.logo {
    appearance: 0;
    background-color: transparent;
    border: 0;

    &:focus {
        outline: none;
    }
}

.noOutline {
    background-color: transparent !important;
    color: #36aca3 !important;
    &:focus {
        color: #fff !important;
        background-color: #36aca3 !important;
    }
    &:hover {
        color: #fff !important;
        background-color: #36aca3 !important;
    }
}

@media (max-width: 320px) {
    .logo {
        img {
            max-width: 100px;
        }
    }
}

@media (max-width: 767px) {
    .mobbtn {
        font-size: 10px;
    }
}