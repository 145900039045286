.toplevel_page_google-sitemap-generator-premium-index {
  &.folded {
    .fixed {
      left: 0;
      width: 100%;
    }

    #wpcontent {
      width: calc(100% - 36px);
      overflow: hidden;

      @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
      }
    }

    .settings-page {
      ul {
        width: calc(100% - 36px);
        left: 36px !important;

        @media (min-width: 961px) {
          left: 36px !important;
          width: calc(100% - 36px);
        }
      }
    }
  }

  .chart-slider-wrapper {
    display: none !important;
  }

  #top-iframe-container {
    position: absolute;
    opacity: 0;
    z-index: -9;
  }

  #wpcontent {
    padding-left: 0;
    width: calc(100% - 160px);
    overflow: hidden;

    @media (max-width: 960px) {
      width: 100%;
      left: 0;
      max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 782px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .container {
    max-width: 100%;
    padding: 0;
  }

  .fixed {
    width: 100%;
    left: 0 !important;
    max-width: 100% !important;
    position: sticky !important;

    @media (min-width: 961px) and (max-width: 1400px) {
      max-width: 100% !important;
      margin: 0;
    }
  }

  .welcome-section-wrapper {
    max-width: 100%;
    width: 100%;

    a {
      color: #36aca3 !important;
    }
  }

  .section-wrapper {
    max-width: 100%;
    overflow: hidden;

    >div {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 100%;
      overflow: visible !important;
    }
  }

  /*---Setting page---*/
  .setting-fixed-title {
    max-width: 100%;
    margin: 0 15px;
  }

  .tabs {
    max-width: 100%;
    display: inline-block;

    @media (max-width: 767px) {
      display: block;
      margin: 0;

      li {
        width: 100%;
      }
    }

    li {
      margin-bottom: 0;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  .settings-container {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .wp-section {
    max-width: 100%;

    ul.list {
      width: 100%;
      left: 0;
      max-width: 100%;
      box-shadow: inset 0px 1px 0px #f3f3f4;
      top: 0;

      @media (max-width: 767px) {
        overflow-x: scroll;
        display: flex !important;
        justify-content: inherit;
      }

      li {
        margin-bottom: 0;
        display: inline-flex;
        width: fit-content;
      }
    }
  }

  .wp-login-notify {
    @media (min-width: 1330px) {
      max-width: 100%;
    }
  }

  input[type="checkbox"]:disabled {
    opacity: 0 !important;
  }

  .selectSet {
    .react-select__value-container {
      position: unset;

      input {
        box-shadow: none !important;
        line-height: inherit !important;
        min-height: inherit !important;
        height: auto;
      }
    }
  }

  .wp-loader {
    right: 0;
    left: 160px;
    transform: none;
    top: 37%,
  }

  .settings-tab {
    ul {
      box-shadow: none !important;
    }
  }

  .container.app-container {
    a {
      color: #36aca3 !important;
    }
  }

}


.response_msg {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #f4f6f9;
  padding: 15px 30px;
  color: #000;
  margin: -60px 0 0;
  position: relative;
}

.response_msg p {
  margin: 0;
}

// Wordpress site changes
.wp-settings-option {
  >div {
    max-width: 695px;
  }

  .option-left {
    max-width: 152px;
  }
}

.buttonPrimary_button {
  max-height: 48px;
}


.setting-priority-wrapper {
  .first-priority-section {
    .wp-post-priority {
      position: relative;

      .radio-wrap {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: auto;

        label {
          padding: 0;
          margin: 0;
          width: 25px;
          height: 25px;
          display: inline-block;

          span {
            position: unset;
            margin: 0;
            padding: 0;
            display: inline-block;
          }
        }
      }
    }
  }
}

.configuration-wrapper {
  .wp-setting-option-sm {
    .wp-option-right {
      width: auto !important;
    }

    input {
      max-width: 152px;
    }
  }
}

.wp-primary-btn {
  button {
    padding: 16px 16px;
  }
}

.wp-heading-weg {
  padding-bottom: 30px;

  h1 {
    font-weight: 300 !important;
    margin-bottom: 6px;
  }

  p {
    margin-top: 0;
  }
}