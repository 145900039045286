@import "../../../assets/scss/variables";

h3.title {
  color: $color_primary;
}

.toplevel_page_auctollo-index {
  #wpbody {
    position: unset;

    .loading-wrapper {
      margin-top: 10px;
      justify-content: center;
      left: 45%;
      padding-bottom: 20px;
      padding-top: 25px;
      // right: 0;
      // top: 10%;
      position: absolute;
      // transform: translate(-50%, -50%);  
      // position: absolute;
    }
  }
}

.loading-wrapper {
  margin-top: 10px;
  // bottom: 0;
  justify-content: center;
  left: 45%;
  padding-bottom: 20px;
  padding-top: 25px;
  // right: 0;
  // top: 10%;
  position: absolute;
  // transform: translate(-50%, -50%);
}

.loader-text{
  margin-top: 20px;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: .5rem;
  font-weight: 500;
  color: #637381 !important;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
  z-index: 9;
}
