.App {
  /*text-align: center;*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#wpbody-content .metabox-holder {
  display: -webkit-inline-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/*-------- Datepicker Card Design ---------*/
.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: #D8FAEA !important;
  border-bottom: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #D8FAEA !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #1A717B !important;
  outline: none !important;
  ;
}

.react-datepicker__navigation--next {
  border-left-color: #1A717B !important;
  outline: none !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    padding: 0 !important;
    margin: 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: #1A717B !important;
  font-size: 12px !important;
  font-family: Poppins, sans-serif !important;
}

.react-datepicker__current-month {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.react-datepicker__day {
  color: #637381 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected {
  background-color: #1A717B !important;
  color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  background-color: #1A717B !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.react-datepicker__day--keyboard-selected {
  color: #ffffff !important;
}

.react-datepicker__day--outside-month {
  color: #DADADA !important;
}

.datecalender:last-child .react-datepicker-popper {
  left: -100px !important;
}

.datecalender:last-child .react-datepicker-popper .react-datepicker__triangle {
  left: 0;
  right: 0;
  margin: -8px auto auto;
}

@media (max-width: 340px) {
  .datecalender:last-child .react-datepicker-popper {
    left: 0px !important;
  }
}