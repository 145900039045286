body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.error-block {
  color: red;
}
.header {
  background: #4286f4;
}

.modal-window {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window-container {
  z-index: 55;
  width: 300px;
  height: 200px;
  background: rgba(235, 235, 235, 1);
}

.App {
  /*text-align: center;*/
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#wpbody-content .metabox-holder {
  display: -webkit-inline-box;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*-------- Datepicker Card Design ---------*/
.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: #D8FAEA !important;
  border-bottom: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #D8FAEA !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #1A717B !important;
  outline: none !important;
  ;
}

.react-datepicker__navigation--next {
  border-left-color: #1A717B !important;
  outline: none !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    padding: 0 !important;
    margin: 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: #1A717B !important;
  font-size: 12px !important;
  font-family: Poppins, sans-serif !important;
}

.react-datepicker__current-month {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.react-datepicker__day {
  color: #637381 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected {
  background-color: #1A717B !important;
  color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  background-color: #1A717B !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.react-datepicker__day--keyboard-selected {
  color: #ffffff !important;
}

.react-datepicker__day--outside-month {
  color: #DADADA !important;
}

.datecalender:last-child .react-datepicker-popper {
  left: -100px !important;
}

.datecalender:last-child .react-datepicker-popper .react-datepicker__triangle {
  left: 0;
  right: 0;
  margin: -8px auto auto;
}

@media (max-width: 340px) {
  .datecalender:last-child .react-datepicker-popper {
    left: 0px !important;
  }
}
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */

/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */

$legacy_browser_support: false !default;

/* Base
   ========================================================================== */

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */

html {
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    @if $legacy_browser_support {
        *font-size: 100%; /* 3 */
    }
}

/**
 * Remove default margin.
 */

body {
    margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
    @if $legacy_browser_support {
        *display: inline;
        *zoom: 1;
    }
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}

/* Links
   ========================================================================== */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
    background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a {

    &:active,
    &:hover {
        outline: 0;
    };
}

/* Text-level semantics
   ========================================================================== */

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
    border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
    font-weight: bold;
}

@if $legacy_browser_support {

    blockquote {
        margin: 1em 40px;
    }
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
    font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

@if $legacy_browser_support {

    h2 {
        font-size: 1.5em;
        margin: 0.83em 0;
    }

    h3 {
        font-size: 1.17em;
        margin: 1em 0;
    }

    h4 {
        font-size: 1em;
        margin: 1.33em 0;
    }

    h5 {
        font-size: 0.83em;
        margin: 1.67em 0;
    }

    h6 {
        font-size: 0.67em;
        margin: 2.33em 0;
    }
}

/**
 * Addresses styling not present in IE 8/9.
 */

mark {
    background: #ff0;
    color: #000;
}

@if $legacy_browser_support {
    /**
     * Addresses margins set differently in IE 6/7.
     */

    p,
    pre {
        *margin: 1em 0;
    }

    /*
     * Addresses CSS quotes not supported in IE 6/7.
     */

    q {
        *quotes: none;
    }

    /*
     * Addresses `quotes` property not supported in Safari 4.
     */

    q:before,
    q:after {
        content: "";
        content: none;
    }
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

@if $legacy_browser_support {
    /* ==========================================================================
       Lists
       ========================================================================== */

    /*
     * Addresses margins set differently in IE 6/7.
     */

    dl,
    menu,
    ol,
    ul {
        *margin: 1em 0;
    }

    dd {
        *margin: 0 0 0 40px;
    }

    /*
     * Addresses paddings set differently in IE 6/7.
     */

    menu,
    ol,
    ul {
        *padding: 0 0 0 40px;
    }

    /*
     * Corrects list images handled incorrectly in IE 7.
     */

    nav ul,
    nav ol {
        *list-style: none;
        *list-style-image: none;
    }

}

/* Embedded content
   ========================================================================== */

/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */

img {
    border: 0;
    @if $legacy_browser_support {
        *-ms-interpolation-mode: bicubic; /* 2 */
    }
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
    overflow: hidden;
}

/* Grouping content
   ========================================================================== */

/**
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
    margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
    box-sizing: content-box;
    height: 0;
}

/**
 * Contain overflow in all browsers.
 */

pre {
    overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
    @if $legacy_browser_support {
        _font-family: "courier new", monospace;
    }
}

/* Forms
   ========================================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
    @if $legacy_browser_support {
        vertical-align: baseline; /* 3 */
        *vertical-align: middle; /* 3 */
    }
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
    overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    @if $legacy_browser_support {
        *overflow: visible;  /* 4 */
    }
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
    line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    @if $legacy_browser_support {
        *height: 13px; /* 3 */
        *width: 13px; /* 3 */
    }
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    box-sizing: content-box; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
    @if $legacy_browser_support {
        white-space: normal; /* 3 */
        *margin-left: -7px; /* 4 */
    }
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
    overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
    font-weight: bold;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}


.serviceWorker_modal-wrapper__2qVtR {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }

.serviceWorker_modal-container__RW4nU {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  overflow: auto;
  padding: 20px;
  width: 30%;
  z-index: 10; }

.serviceWorker_get-started-link__4HCKD {
  align-self: flex-start;
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
  color: #fff;
  padding: 7.3px 16px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  transition: all 0.2s linear; }
  .serviceWorker_get-started-link__4HCKD:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff;
    text-decoration: none; }

.list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

h1.title {
  color: #1f3041;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.5; }

h2.title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400; }

h3.title {
  color: #637381;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5; }

h4.title {
  color: #32373c;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5; }

h5.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625; }

p.paragraph {
  line-height: 1.5; }

.container {
  max-width: 1300px; }

.section {
  padding: 30px 0;
  position: relative; }
  .section-heading {
    text-align: center; }
  .section-title {
    margin-bottom: 6px; }
  .section-subtitle {
    font-size: 14px;
    margin-bottom: 6px; }
  .section-body {
    padding-top: 13px; }

.min-height-400 {
  min-height: 470px; }

.sign-up-form {
  border-radius: 6px;
  margin: 0 auto;
  max-width: 400px;
  padding: 20px 24px 24px; }
  .sign-up-form h4 {
    margin-bottom: 0; }

.d-flex {
  display: flex; }

.flex-column {
  flex-direction: column; }

.justify-content-between {
  justify-content: space-between; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: flex-end; }

.align-items-center {
  align-items: center; }

.bg-white {
  background-color: #fff; }

.dash-margin-bottom {
  margin-bottom: 50px; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.with-shadow {
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15); }

.fixed {
  max-width: inherit;
  position: -webkit-sticky;
  position: sticky;
  width: inherit; }

.sign-up-form-wrapper {
  border-radius: 6px;
  margin: 0 auto;
  max-width: 400px;
  padding: 32px 24px 24px; }

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px; }

.list-pricing-plans .list-item:first-child .card-subtitle, .list-pricing-plans .list-item:last-child .card-subtitle {
  max-width: 190px; }

.statistic-list {
  padding: 21px 8px 16px; }

.statistic-item:not(:last-child) {
  border-right: 1px solid #ececec; }

.header-shadow {
  box-shadow: inset 0px -1px 0px #f3f3f4; }

.btn-primary {
  align-items: center;
  background-color: #36aca3 !important;
  border: 1px solid #36aca3 !important;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  padding: 10px 16px;
  transition: all 0.2s linear;
  width: auto;
  outline: none; }
  .btn-primary:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff; }
  .btn-primary:focus {
    background-color: #28857f !important;
    border-color: #28857f !important;
    box-shadow: none !important;
    color: #fff; }
  .btn-primary:active {
    background-color: #28857f !important;
    border-color: #28857f !important;
    box-shadow: none !important;
    color: #fff; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.row-with-5-col .col {
  padding: 0 15px;
  width: 20%; }

.row-with-4-col .col {
  padding: 0 15px;
  width: 25%; }

.popover-body {
  padding: 0; }
  .popover-body .popuplink {
    line-height: 2; }
    .popover-body .popuplink a {
      transition: all 0.2s;
      padding: 12px 16px;
      display: inline-block;
      width: 100%;
      font-family: Poppins, sans-serif !important;
      font-size: 14px !important;
      font-weight: normal !important;
      color: #637381 !important;
      line-height: 1.5; }
      .popover-body .popuplink a:hover {
        background: #f4f6f9 !important; }
  .popover-body .dropdown-divider {
    margin: 0; }

.add-btn {
  color: #36aca3;
  cursor: pointer;
  margin-right: 16px; }

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 769px) {
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 768px) {
  h1.title {
    font-size: 25px; }
  h2.title {
    font-size: 22px; }
  h3.title {
    font-size: 18px; }
  h4.title {
    font-size: 16px; }
  h5.title {
    font-size: 16px; }
  .row-with-5-col {
    flex-wrap: wrap; }
    .row-with-5-col .col {
      margin-bottom: 16px;
      width: 33.33%; }
      .row-with-5-col .col:nth-child(4) {
        margin-bottom: 0;
        width: 50%; }
      .row-with-5-col .col:nth-child(5) {
        margin-bottom: 0;
        width: 50%; }
  .row-with-4-col {
    flex-wrap: wrap; }
    .row-with-4-col .col {
      margin-bottom: 16px;
      width: 50%; }
  .col-md-3.statistic-item {
    width: 50%; }
    .col-md-3.statistic-item:first-child, .col-md-3.statistic-item:nth-child(2) {
      border-bottom: 1px solid #ececec; } }

@media (max-width: 767px) {
  .row-with-5-col {
    flex-wrap: wrap; }
    .row-with-5-col .col {
      margin-bottom: 16px;
      width: 100%; }
      .row-with-5-col .col.statistic-item {
        margin: 0;
        padding-bottom: 15px;
        border-right: none;
        width: 100%; }
        .row-with-5-col .col.statistic-item:not(:last-child) {
          border-bottom: 1px solid #ececec; }
  .row-with-4-col {
    flex-wrap: wrap; }
    .row-with-4-col .col {
      margin-bottom: 16px;
      width: 100%; }
      .row-with-4-col .col.statistic-item {
        margin: 0;
        padding-bottom: 15px;
        border-right: none;
        width: 100%; }
        .row-with-4-col .col.statistic-item:not(:last-child) {
          border-bottom: 1px solid #ececec; }
  .statistic-list {
    display: block; }
    .statistic-list .statistic-item {
      margin: 0 0 15px;
      padding-bottom: 15px;
      border-right: none;
      width: 100%; }
      .statistic-list .statistic-item:not(:last-child) {
        border-bottom: 1px solid #ececec; }
  .tab-section ul li {
    padding: 0 25px 10px; }
  .pricing-card-wrapper {
    display: block !important; }
    .pricing-card-wrapper .pricing-body {
      padding: 0;
      width: 100%;
      text-align: center; }
      .pricing-card-wrapper .pricing-body h4,
      .pricing-card-wrapper .pricing-body h5 {
        width: 100%;
        max-width: 100% !important; }
      .pricing-card-wrapper .pricing-body h5 {
        font-size: 16px;
        line-height: 22px;
        margin: 10px 0; }
    .pricing-card-wrapper .pricing-actions {
      justify-content: center; } }

@media (max-width: 480px) {
  h1.title {
    font-size: 20px; }
  .section {
    padding-top: 0; }
    .section-body {
      padding-top: 0; }
  h3.title {
    font-size: 16px; }
  p {
    line-height: 1.2; } }

#braintree-field-wrapper-1 iframe {
  height: 48px !important; }

input.expirationDate {
  background-color: #fff !important;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  color: #637381 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 48px !important;
  padding: 8px 16px !important; }

input#expiration.expirationDate {
  background-color: #fff !important;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  color: #637381 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 48px !important;
  padding: 8px 16px !important; }

.expDate {
  display: flex;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  height: 48px !important; }
  .expDate body {
    height: 48px !important; }
    .expDate body iframe {
      height: 36px !important; }
  .expDate input {
    background-color: #fff !important;
    border: 1px solid #8996a2 !important;
    border-radius: 6px !important;
    color: #637381 !important;
    font-size: 14px !important;
    font-weight: normal !important;
    height: 36px !important; }

.btn-purchase {
  margin-top: 20px;
  margin-left: 20px; }

/* Setting page design */
.settings-label-wrapper {
  position: relative; }
  .settings-label-wrapper .label-title {
    position: absolute;
    top: 2px;
    left: 45px; }

.setting-checkbox-wrapper .list-wrap {
  width: 50%;
  display: inline-flex; }

.wp-exclusive-categories label span {
  top: 4px; }

.navigation_nav-menu__gRZ6q a:not(.navigation_get-started-link__2kdeG) {
  color: #666;
  font-size: 14px; }

.navigation_nav-menu__gRZ6q .navigation_list-item__3e-Q7:not(:last-child) {
  margin-right: 40px; }

.navigation_get-started-link__2kdeG {
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
  color: #fff;
  padding: 7.3px 16px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  transition: all 0.2s linear; }
  .navigation_get-started-link__2kdeG:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff;
    text-decoration: none; }

.navigation_noOutline__M1hi2 {
  background-color: transparent !important;
  color: #36aca3 !important; }
  .navigation_noOutline__M1hi2:focus {
    color: #fff !important;
    background-color: #36aca3 !important; }
  .navigation_noOutline__M1hi2:hover {
    color: #fff !important;
    background-color: #36aca3 !important; }

@media (max-width: 920px) {
  .navigation_nav-menu__gRZ6q {
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: flex-start;
    max-width: 414px;
    padding: 0;
    position: absolute;
    right: -9px;
    top: 32px;
    width: 100vw;
    z-index: 9; }
    .navigation_nav-menu__gRZ6q .navigation_list-item__3e-Q7 {
      padding: 8px 16px;
      width: 100%; }
      .navigation_nav-menu__gRZ6q .navigation_list-item__3e-Q7:not(:last-child) {
        margin-right: 0; }
  .navigation_get-started-link__2kdeG {
    background-color: #fff;
    border: 0;
    box-shadow: none;
    color: #36aca3;
    padding: 0;
    text-decoration: underline;
    white-space: nowrap; } }

@media (max-width: 767px) {
  .navigation_nav-menu__gRZ6q .navigation_get-started-link__2kdeG:hover {
    background-color: transparent;
    border: none;
    color: #666;
    font-weight: 400; } }

@media (min-width: 361px) and (max-width: 767px) {
  .navigation_nav-menu__gRZ6q {
    max-width: 340px;
    top: 42px; } }

@media (max-width: 360px) {
  .navigation_nav-menu__gRZ6q {
    max-width: 290px;
    top: 36px; } }

.dropdown_dropdown-menu__1NCzi {
  display: none;
  max-width: 282px;
  position: absolute;
  right: 0;
  top: 48px;
  width: 100%;
  z-index: 99; }
  .dropdown_dropdown-menu__1NCzi.dropdown_opened__3rpKY {
    display: block;
    z-index: 99;
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }

.dropdown_dropdown-link__2N4I1 {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 0;
  color: #666;
  cursor: pointer;
  padding: 16px;
  text-align: left;
  width: 100%; }
  .dropdown_dropdown-link__2N4I1:hover {
    background-color: #f4f6f9; }
  .dropdown_dropdown-link__2N4I1:focus {
    outline: none; }

.dropdown_dropdown__XHtU- li {
  flex-direction: column; }

.dropdown_toggle-btn__uYEnx {
  -webkit-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  color: #4f4f4f;
  font-weight: 500; }
  .dropdown_toggle-btn__uYEnx:focus {
    outline: none; }
  .dropdown_toggle-btn__uYEnx:hover {
    color: #637381; }
  .dropdown_toggle-btn__uYEnx img {
    border-radius: 50%;
    height: 16px;
    margin-right: 7px;
    object-fit: cover;
    width: 16px; }

.dropdown_badge__2wQQd {
  align-items: center;
  background-color: #d16d4b;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 16px;
  justify-content: center;
  line-height: 1;
  margin-left: 26px;
  width: 16px; }

.dropdown_logged-as__3HBxs {
  display: none; }

@media (max-width: 920px) {
  .dropdown_logged-as__3HBxs {
    display: inline-flex;
    padding-right: 20px; }
  .dropdown_dropdown-wrapper__2_lwM {
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    max-width: 414px;
    padding: 0;
    position: absolute;
    right: -9px;
    top: 32px;
    width: 100vw;
    z-index: 9; }
  .dropdown_dropdown-menu__1NCzi {
    box-shadow: none;
    display: block;
    position: static; }
  .dropdown_toggle-btn__uYEnx {
    color: #666;
    font-weight: 400;
    padding: 16px; }
  .dropdown_upgrade-link__1CanF {
    color: #36aca3; } }

.topPanel_panel__1hJhV {
  max-width: 100%;
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 99; }
  .topPanel_panel-left__1rstj {
    align-items: center; }
  .topPanel_panel-right__DuQNL p {
    color: #666;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0; }
  .topPanel_panel-title__29l_x {
    align-items: center;
    color: #165362;
    margin-bottom: 0;
    margin-left: 12px;
    margin-top: 0; }
  .topPanel_panel-content__BPGs4 {
    margin: 0 auto;
    position: relative; }

.topPanel_toggle-nav__28wuS {
  cursor: pointer;
  display: none;
  height: 16px;
  margin-left: auto;
  margin-right: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 19px;
  z-index: 99; }
  .topPanel_toggle-nav__28wuS span {
    background: #36aca3;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%; }
    .topPanel_toggle-nav__28wuS span:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
    .topPanel_toggle-nav__28wuS span:nth-child(2) {
      top: 6px;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
    .topPanel_toggle-nav__28wuS span:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
  .topPanel_toggle-nav__28wuS.topPanel_active__2WQ9x span:nth-child(1) {
    left: 8px;
    top: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .topPanel_toggle-nav__28wuS.topPanel_active__2WQ9x span:nth-child(2) {
    opacity: 0;
    width: 0; }
  .topPanel_toggle-nav__28wuS.topPanel_active__2WQ9x span:nth-child(3) {
    left: 8px;
    top: 13px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

@media (max-width: 920px) {
  .topPanel_toggle-nav__28wuS {
    display: block;
    margin-right: 10px; }
  .topPanel_nav-wrapper__3QYyS {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    transition: all 400ms ease-in-out; }
    .topPanel_nav-wrapper__3QYyS.topPanel_open__3MXEQ {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition: all 400ms ease-in-out; } }

@media (max-width: 480px) {
  .topPanel_panel__1hJhV {
    margin: 0;
    width: auto; } }


.pricingPlanCard_card__iWpPy {
  align-items: center;
  border-radius: 6px;
  flex-direction: column;
  max-width: 328px;
  min-height: 430px;
  padding: 32px;
  position: relative;
  text-align: center; }
  .pricingPlanCard_card-action__3VsS9 {
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%; }
  .pricingPlanCard_card-icon__1qy7r {
    align-items: center;
    background-color: #d2f5fb;
    border-radius: 50%;
    height: 104px;
    justify-content: center;
    margin: 0 auto 24px;
    width: 103px; }
  .pricingPlanCard_card-title__5V0lW {
    margin-bottom: 19px;
    margin-top: 0; }
  .pricingPlanCard_card-subtitle__20_vz {
    color: #979797; }
  .pricingPlanCard_card-body__2FtAP {
    display: flex;
    flex: 1 1;
    flex-direction: column; }

.pricingPlanCard_icon-checked__3c_qE {
  align-items: center;
  background-color: #d8faea;
  border-radius: 50%;
  height: 20px;
  justify-content: center;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 20px; }
  .pricingPlanCard_icon-checked__3c_qE svg {
    height: 10px;
    width: 13px; }

@media (max-width: 768px) {
  .pricingPlanCard_card__iWpPy {
    margin: 0 auto 25px; } }

@media (max-width: 480px) {
  .pricingPlanCard_card__iWpPy {
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 12px;
    max-width: 398px;
    min-height: unset;
    padding: 16px 24px 24px;
    width: 95%; }
    .pricingPlanCard_card-body__2FtAP {
      padding-left: 22px;
      text-align: left; }
    .pricingPlanCard_card-action__3VsS9 {
      justify-content: flex-start; }
    .pricingPlanCard_card__iWpPy .pricingPlanCard_card-title__5V0lW {
      font-size: 18px;
      margin-bottom: 8px; }
    .pricingPlanCard_card__iWpPy .pricingPlanCard_card-subtitle__20_vz {
      font-size: 16px; } }

.buttonPrimary_button__2X1eM {
  align-items: center;
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  padding: 10px 16px;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonPrimary_button__2X1eM:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff; }
  .buttonPrimary_button__2X1eM.buttonPrimary_btn-center__3ZM9Z {
    margin-left: auto;
    margin-right: auto; }
  .buttonPrimary_button__2X1eM.buttonPrimary_small__1pkGa {
    max-width: 159px; }
  .buttonPrimary_button__2X1eM.buttonPrimary_medium__2oz46 {
    max-width: 176px; }
  .buttonPrimary_button__2X1eM.buttonPrimary_large__1rZKb {
    max-width: 200px; }
  .buttonPrimary_button__2X1eM.buttonPrimary_with-icon__QqnXJ {
    justify-content: space-evenly; }
    .buttonPrimary_button__2X1eM.buttonPrimary_with-icon__QqnXJ:hover svg path {
      fill: #fff; }
    .buttonPrimary_button__2X1eM.buttonPrimary_with-icon__QqnXJ svg {
      margin-right: 14%; }
  .buttonPrimary_button__2X1eM:focus {
    outline: none; }
  .buttonPrimary_button__2X1eM:disabled {
    background-color: transparent;
    color: #36aca3;
    cursor: not-allowed; }

.logo_logo__36DPR {
  -webkit-appearance: 0;
          appearance: 0;
  background-color: transparent;
  border: 0; }
  .logo_logo__36DPR:focus {
    outline: none; }

.logo_noOutline__1a-Iv {
  background-color: transparent !important;
  color: #36aca3 !important; }
  .logo_noOutline__1a-Iv:focus {
    color: #fff !important;
    background-color: #36aca3 !important; }
  .logo_noOutline__1a-Iv:hover {
    color: #fff !important;
    background-color: #36aca3 !important; }

@media (max-width: 320px) {
  .logo_logo__36DPR img {
    max-width: 100px; } }

@media (max-width: 767px) {
  .logo_mobbtn__2NNA8 {
    font-size: 10px; } }

.buttonSignUp_toplevel_page_auctollo-index__gLXCU,
.buttonSignUp_toplevel_page_google-sitemap-generator-premium-index__b_Qay {
  cursor: pointer; }

.buttonSignUp_button__E1C6D {
  align-items: center;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  height: 48px;
  justify-content: center;
  line-height: 1;
  padding: 4px 10px 4px 54px;
  position: relative;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonSignUp_button__E1C6D .buttonSignUp_icon-wrapper__2PYsm {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    height: 40px;
    justify-content: center;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 40px; }
  .buttonSignUp_button__E1C6D.buttonSignUp_blue__vqzVl {
    background-color: #518ef8;
    border: 1px solid #518ef8; }
    .buttonSignUp_button__E1C6D.buttonSignUp_blue__vqzVl:hover {
      background-color: #206ef6;
      border-color: #206ef6;
      color: #fff; }
  .buttonSignUp_button__E1C6D.buttonSignUp_red__3yOXy {
    background-color: #eb3b00;
    border: 1px solid #eb3b00; }
    .buttonSignUp_button__E1C6D.buttonSignUp_red__3yOXy:hover {
      background-color: #b82e00;
      border-color: #b82e00;
      color: #fff; }
  .buttonSignUp_button__E1C6D.buttonSignUp_black__A6enx {
    background-color: #191717;
    border: 1px solid #eb3b00;
    border: 1px solid #191717; }
    .buttonSignUp_button__E1C6D.buttonSignUp_black__A6enx:hover {
      background-color: #010000;
      border-color: #010000;
      color: #fff; }
  .buttonSignUp_button__E1C6D:hover {
    background-color: #fff;
    text-decoration: none; }

.input_input__3A2qp {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .input_input__3A2qp:-webkit-autofill, .input_input__3A2qp:-webkit-autofill:hover, .input_input-webkit-autofill__1MMG2:focus, .input_input-webkit-autofill__1MMG2, .input_input-webkit-autofill__1MMG2:hover, .input_input-webkit-autofill__1MMG2:focus, .input_input-webkit-autofill__1MMG2, .input_input-webkit-autofill__1MMG2:hover, .input_input-webkit-autofill__1MMG2:focus {
    border: 1px solid #8996a2;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s; }
  .input_input__3A2qp:focus {
    border-color: #36aca3; }

.radioButton_container__X8Lqp {
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  margin-bottom: 0;
  padding: 14px 35px 8px 16px;
  position: relative;
  -webkit-user-select: none;
          user-select: none; }
  .radioButton_container__X8Lqp:hover {
    border-color: #36aca3; }
  .radioButton_container__X8Lqp.radioButton_active__2X3gb {
    border: 2px solid #36aca3;
    color: #36aca3; }

.radioButton_container__X8Lqp input {
  cursor: pointer;
  opacity: 0;
  position: absolute; }

.radioButton_checkmark__3uKQ4 {
  border: 1px solid #637381;
  border-radius: 50%;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 13px;
  width: 20px; }

.radioButton_container__X8Lqp input:checked ~ .radioButton_checkmark__3uKQ4 {
  background-color: #36aca3;
  border: 0; }

.radioButton_checkmark__3uKQ4:after {
  content: "";
  display: none;
  position: absolute; }

.radioButton_container__X8Lqp input:checked ~ .radioButton_checkmark__3uKQ4:after {
  display: block; }

.radioButton_container__X8Lqp .radioButton_checkmark__3uKQ4:after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.07573 8.7728L0.175729 4.8728C-0.0585762 4.63849 -0.0585762 4.25859 0.175729 4.02426L1.02424 3.17573C1.25854 2.9414 1.63846 2.9414 1.87277 3.17573L4.5 5.80294L10.1272 0.175729C10.3615 -0.0585762 10.7415 -0.0585762 10.9758 0.175729L11.8243 1.02426C12.0586 1.25857 12.0586 1.63846 11.8243 1.87279L4.92426 8.77282C4.68994 9.00713 4.31004 9.00713 4.07573 8.7728Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  height: 10px;
  left: 5px;
  top: 6px;
  width: 12px; }

.buttonOutline_button__2MyBq {
  align-items: center;
  background-color: transparent;
  border: 1px solid #36aca3;
  border-radius: 6px;
  color: #36aca3;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 351px;
  padding: 10px 16px;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonOutline_button__2MyBq:hover {
    background-color: #36aca3;
    color: #fff; }
  .buttonOutline_button__2MyBq:focus {
    outline: none; }

.input_input__2PMkW,
input[type=text],
input[type=email],
input[type=password] {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .input_input__2PMkW:-webkit-autofill, .input_input__2PMkW:-webkit-autofill:hover, .input_input-webkit-autofill__2oE1I:focus, .input_input-webkit-autofill__2oE1I, .input_input-webkit-autofill__2oE1I:hover, .input_input-webkit-autofill__2oE1I:focus, .input_input-webkit-autofill__2oE1I, .input_input-webkit-autofill__2oE1I:hover, .input_input-webkit-autofill__2oE1I:focus,
  input[type=text]:-webkit-autofill,
  input[type=text]:-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=text]-webkit-autofill,
  input[type=text]-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=text]-webkit-autofill,
  input[type=text]-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=email]:-webkit-autofill,
  input[type=email]:-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=email]-webkit-autofill,
  input[type=email]-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=email]-webkit-autofill,
  input[type=email]-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=password]:-webkit-autofill,
  input[type=password]:-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus,
  input[type=password]-webkit-autofill,
  input[type=password]-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus,
  input[type=password]-webkit-autofill,
  input[type=password]-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus {
    border: 1px solid #8996a2;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s; }
  .input_input-webkit-input-placeholder__3skow,
  input[type=text]-webkit-input-placeholder,
  input[type=email]-webkit-input-placeholder,
  input[type=password]-webkit-input-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-moz-placeholder__1pp8Z,
  input[type=text]-moz-placeholder,
  input[type=email]-moz-placeholder,
  input[type=password]-moz-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-ms-input-placeholder__3p1ye,
  input[type=text]-ms-input-placeholder,
  input[type=email]-ms-input-placeholder,
  input[type=password]-ms-input-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-moz-placeholder__1pp8Z,
  input[type=text]-moz-placeholder,
  input[type=email]-moz-placeholder,
  input[type=password]-moz-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .input_input__2PMkW:focus,
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=password]:focus {
    border-color: #36aca3; }

.input_input-error__1-h2O {
  border-color: #dc3824 !important;
  color: #dc3824 !important; }

.input_error-block__G5x_Z {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.select_error-block__3tU_5 {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.section-fixed-top {
    padding-top: 60px;
    max-width: 100%;
    /* max-width: 1280px; */
    /* margin: auto; */
    margin: 0 15px;
}

.section-wrapper {
    max-width: 100%;
    /* max-width: 1280px; */
    margin: auto;
    /* padding: 0 15px; */
}

.section-wrapper>div {
    overflow: inherit !important;
}

.forgot-password h1 {
    margin-top: 18.760px;
    margin-bottom: 6px;
}

.signin-section h1 {
    margin-bottom: 6px;

}

.text-middle {
    text-align: center;
}

.text-middle a {
    color: #36aca3 !important;
}

@media (max-width: 767px) {
    .section-fixed-top {
        max-width: 100%;
        position: relative;
        padding-top: 0;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .section-fixed-top {
        max-width: 100%;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .section-fixed-top {
        max-width: 100%;
        /* max-width: 984px; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 984px; */
    }
}

@media (min-width: 1330px) {
    .section-fixed-top {
        max-width: 100%;
        /* max-width: 1280px; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 1280px; */
    }
}

@media (max-width: 1380px) and (min-width: 1025px) {
    .section-fixed-top {
        max-width: 100%;
    }

    .section-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .section-fixed-top {
        padding-top: 60px;
        max-width: 100%;
        /* max-width: 1280px; */
        /* margin: auto; */
    }

    .section-wrapper {
        max-width: 100%;
        /* max-width: 1280px; */
    }
}


/* .selected-plan-form form {
    padding-top: 4px;
} */
/* .section-fixed-top.signin-section {
    padding-top: 30px;
}*/
/* 
.section-fixed-top.signup-section {
    padding-top: 30px;
}  */
.tabs_tabs__3tk0J {
  box-shadow: none;
  cursor: pointer;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 100%;
  display: block !important; }
  @media (max-width: 767px) {
    .tabs_tabs__3tk0J {
      overflow-x: scroll; } }
  .tabs_tabs__3tk0J > li {
    border-bottom: 4px solid transparent;
    color: #1f3041;
    padding: 16px 3.31% 8px;
    display: inline-block; }
    .tabs_tabs__3tk0J > li.tabs_selected__2SFIw {
      border-bottom-color: #36aca3;
      color: #36aca3; }

@media (max-width: 768px) {
  .tabs_tabs__3tk0J li {
    font-size: 12px; } }

@media (max-width: 480px) {
  .tabs_tabs__3tk0J {
    justify-content: space-between;
    margin: 0 -10px;
    width: auto; }
    .tabs_tabs__3tk0J li {
      padding: 16px 8px 8px; } }

.label_label__3hj-9 {
  border-radius: 20px;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  padding: 1px 9px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .label_label__3hj-9.label_warning__cBI-y {
    background-color: #fef8d8;
    color: #a97c21; }
  .label_label__3hj-9 svg {
    margin-bottom: 2px;
    margin-right: 3px; }
  .label_label__3hj-9.label_active__2lilk {
    background-color: #d8faea;
    color: #1a717b; }

.cardStatistic_card-heading__vMKbd {
  color: #1f3041;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.27; }

.cardStatistic_card-subtitle__17yRF {
  margin-bottom: 7px;
  text-align: center; }

@media (max-width: 767px) {
  .cardStatistic_card__145BZ.cardStatistic_with-icon__WUUMk {
    flex-direction: row;
    padding: 8px 6px 0; } }

@media (max-width: 480px) {
  .cardStatistic_card-heading__vMKbd {
    font-size: 24px;
    padding-bottom: 4px; }
  .cardStatistic_card-subtitle__17yRF {
    line-height: 1.2;
    margin-bottom: 10px; }
  .cardStatistic_card__145BZ.cardStatistic_with-icon__WUUMk {
    flex-direction: row;
    padding: 8px 6px 0; }
    .cardStatistic_card__145BZ.cardStatistic_with-icon__WUUMk .cardStatistic_card-heading__vMKbd svg {
      height: 24px;
      margin-right: 8px;
      width: 24px; }
    .cardStatistic_card__145BZ.cardStatistic_with-icon__WUUMk .cardStatistic_card-subtitle__17yRF {
      margin-bottom: 0;
      padding-right: 18px; } }

.heading_heading__38eUq {
  padding: 0 32px; }
  .heading_heading__38eUq.heading_center__1SgAp {
    text-align: center; }
    .heading_heading__38eUq.heading_center__1SgAp .heading_subtitle__3Yt0r {
      margin-left: auto;
      margin-right: auto;
      max-width: 345px; }
  .heading_heading__38eUq.heading_left__3mj3x {
    text-align: left; }
  .heading_heading__38eUq.heading_right__1WszO {
    text-align: right; }

h1.heading_title__EVpr6 {
  line-height: 1.27; }

@media (max-width: 480px) {
  .heading_heading__38eUq {
    padding-bottom: 0; } }

/*-------- Datepicker Design ---------*/
.heading_datepickerWrapper__3xBp1 {
  display: flex;
  align-items: center; }
  .heading_datepickerWrapper__3xBp1 .heading_datewrapper__ShfAi {
    position: relative;
    cursor: pointer; }
    .heading_datepickerWrapper__3xBp1 .heading_datewrapper__ShfAi input {
      border-radius: 25px;
      border: 1px solid #637381;
      padding: 5px 12px 4px 40px;
      position: relative;
      color: #637381;
      font-size: 14px;
      line-height: 21px;
      width: 140px;
      cursor: pointer; }
    .heading_datepickerWrapper__3xBp1 .heading_datewrapper__ShfAi:after {
      content: "";
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      background: url(https://app.auctollo.com/static/media/calender.d1b1c8a7.svg);
      height: 16px;
      width: 14px;
      margin: auto; }
  .heading_datepickerWrapper__3xBp1 .heading_arrow__ap3Xk {
    margin: 0 8px; }

@media (max-width: 340px) {
  .heading_datepickerWrapper__3xBp1 {
    display: inline-block;
    width: 100%; } }

.sectionHeading_section-heading__I6OjC {
  margin-bottom: 13px;
  margin-left: 20px; }

.sectionHeading_settings-link__3AR9G {
  color: #637381;
  font-size: 16px; }
  .sectionHeading_settings-link__3AR9G span {
    padding-left: 8px; }
  .sectionHeading_settings-link__3AR9G svg {
    height: 16px;
    width: 16px; }
    .sectionHeading_settings-link__3AR9G svg path {
      fill: #637381; }
  .sectionHeading_settings-link__3AR9G:hover {
    text-decoration: none; }
    .sectionHeading_settings-link__3AR9G:hover path {
      fill: #36aca3; }

@media (max-width: 480px) {
  .sectionHeading_settings-link__3AR9G {
    font-size: 14px; }
  .sectionHeading_section-heading__I6OjC {
    margin-bottom: 6px; } }

.seeMore_see-more-wrapper__1ooiI {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  padding: 0 20px 20px;
  position: absolute;
  right: 0;
  top: 0; }

h3.dataIsLoading_title__2_7CX {
  color: #36aca3; }

.dataIsLoading_loading-wrapper__3z9eh {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

@-webkit-keyframes dataIsLoading_rotating__23jzL {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes dataIsLoading_rotating__23jzL {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.dataIsLoading_rotating__23jzL {
  -webkit-animation: dataIsLoading_rotating__23jzL 2s linear infinite;
          animation: dataIsLoading_rotating__23jzL 2s linear infinite; }

.dataIsLoading_empty-data-loading__JlSUk {
  position: relative; }

.table_tableresponsive__3kxgl {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table_tableresponsive__3kxgl .table_table__-Qz1x {
    width: 100%;
    max-width: 100%; }
    .table_tableresponsive__3kxgl .table_table__-Qz1x th {
      color: #1f3041;
      font-weight: 500;
      padding: 21px 16px 12px; }
    .table_tableresponsive__3kxgl .table_table__-Qz1x td {
      color: #666;
      padding: 14px 16px; }
    .table_tableresponsive__3kxgl .table_table__-Qz1x th,
    .table_tableresponsive__3kxgl .table_table__-Qz1x td {
      border-bottom: 1px solid #d3d8dd; }
    .table_tableresponsive__3kxgl .table_table__-Qz1x th:last-child,
    .table_tableresponsive__3kxgl .table_table__-Qz1x td:last-child {
      text-align: right; }
    .table_tableresponsive__3kxgl .table_table__-Qz1x .table_align-center__1TUmk {
      text-align: center; }

.switchButton_switch-container__2Zk7W {
  cursor: pointer;
  padding: 4px;
  position: relative; }

.switchButton_switch__32GRx {
  opacity: 0;
  position: absolute; }
  .switchButton_switch__32GRx + div {
    background-color: #f3f3f3;
    border-radius: 999px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
    vertical-align: middle; }
  .switchButton_switch__32GRx:checked + div {
    background-color: #3b89ec;
    width: 48px; }
  .switchButton_switch__32GRx + div {
    height: 16px;
    width: 48px; }
  .switchButton_switch__32GRx:checked + div {
    background-color: rgba(54, 172, 163, 0.3); }
  .switchButton_switch__32GRx + div > div {
    background: #d3d8dd;
    border-radius: inherit;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    height: 24px;
    margin-left: 1px;
    margin-top: 1px;
    pointer-events: none;
    position: relative;
    top: -3px;
    transition-duration: 0.3s;
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
    transition-timing-function: ease-in-out;
    width: 24px; }
  .switchButton_switch__32GRx:checked + div > div {
    background-color: #36aca3;
    -webkit-transform: translate3d(22px, 0, 0);
            transform: translate3d(22px, 0, 0); }

.checkbox_container__2Ep2V {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
          user-select: none; }
  .checkbox_container__2Ep2V input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.checkbox_checkmark__18bxr {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 2px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .checkbox_checkmark__18bxr:after {
    content: "";
    display: none;
    position: absolute; }

.checkbox_container__2Ep2V:hover input ~ .checkbox_checkmark__18bxr {
  background-color: #f3f3f3; }

.checkbox_container__2Ep2V input:checked ~ .checkbox_checkmark__18bxr {
  background-color: #36aca3;
  border-color: #36aca3; }
  .checkbox_container__2Ep2V input:checked ~ .checkbox_checkmark__18bxr:after {
    display: block; }

.checkbox_container__2Ep2V .checkbox_checkmark__18bxr:after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7153 1.79992L6.78464 0.866592C6.76916 0.85107 6.75076 0.838756 6.73052 0.830354C6.71027 0.821952 6.68856 0.817627 6.66664 0.817627C6.64471 0.817627 6.62301 0.821952 6.60276 0.830354C6.58251 0.838756 6.56412 0.85107 6.54864 0.866592L2.61797 4.79992C2.60249 4.81545 2.5841 4.82776 2.56385 4.83616C2.5436 4.84456 2.52189 4.84889 2.49997 4.84889C2.47805 4.84889 2.45634 4.84456 2.43609 4.83616C2.41584 4.82776 2.39745 4.81545 2.38197 4.79992L1.4513 3.86659C1.43582 3.85107 1.41743 3.83876 1.39718 3.83035C1.37693 3.82195 1.35523 3.81763 1.3333 3.81763C1.31138 3.81763 1.28967 3.82195 1.26943 3.83035C1.24918 3.83876 1.23079 3.85107 1.2153 3.86659L0.284638 4.79992C0.253392 4.83118 0.23584 4.87356 0.23584 4.91776C0.23584 4.96195 0.253392 5.00434 0.284638 5.03559L2.38197 7.13326C2.39745 7.14878 2.41584 7.16109 2.43609 7.1695C2.45634 7.1779 2.47805 7.18222 2.49997 7.18222C2.52189 7.18222 2.5436 7.1779 2.56385 7.1695C2.5841 7.16109 2.60249 7.14878 2.61797 7.13326L7.7153 2.03326C7.74584 2.0021 7.76295 1.96022 7.76295 1.91659C7.76295 1.87297 7.74584 1.83108 7.7153 1.79992Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  left: 3px;
  top: 3px;
  width: 9px; }

.settingsHeading_heading__1gZvQ {
  margin-left: 10px; }

.select_select__1BN6a {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2;
  border-radius: 6px;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  min-width: 152px;
  padding: 8px 16px; }

.switch_switch-container__1beem {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 4px;
  position: relative; }

.switch_active__3Txi7 {
  color: #36aca3; }

.switch_option-title__2hmJ8 {
  color: #637381;
  font-weight: 600;
  margin-bottom: 4px; }

.switch_option-label__q1cky {
  margin-right: 16px; }
  .switch_option-label__q1cky + div {
    padding: 0; }
    .switch_option-label__q1cky + div label {
      margin-bottom: 0; }

.switch_switch__1C3_J {
  opacity: 0;
  position: absolute; }
  .switch_switch__1C3_J + div {
    background-color: #f3f3f3;
    border-radius: 999px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
    vertical-align: middle; }
  .switch_switch__1C3_J:checked + div {
    background-color: #3b89ec;
    width: 48px; }
  .switch_switch__1C3_J + div {
    height: 16px;
    width: 48px; }
  .switch_switch__1C3_J:checked + div {
    background-color: rgba(54, 172, 163, 0.3); }
  .switch_switch__1C3_J + div > div {
    background: #d3d8dd;
    border-radius: inherit;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    height: 24px;
    margin-left: 1px;
    margin-top: 1px;
    pointer-events: none;
    position: relative;
    top: -3px;
    transition-duration: 0.3s;
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
    transition-timing-function: ease-in-out;
    width: 24px; }
  .switch_switch__1C3_J:checked + div > div {
    background-color: #36aca3;
    -webkit-transform: translate3d(22px, 0, 0);
            transform: translate3d(22px, 0, 0); }

.switch_settings__19-DN {
  background-color: #f4f6f9;
  padding-top: 35px; }
  .switch_settings__19-DN .switch_title__2zBwH {
    color: #32373c;
    font-weight: normal;
    margin-bottom: 14px; }
  .switch_settings__19-DN .switch_option-title__2hmJ8 {
    color: #637381;
    font-weight: 600;
    margin-bottom: 4px; }
  .switch_settings-page__1bcSt {
    background-color: #fff; }
  .switch_settings-wrapper__2O5LL > div > ul {
    box-shadow: none;
    margin: 0 auto;
    max-width: 1040px; }
  .switch_settings-container__21pLl {
    margin: 0 auto;
    max-width: 1056px; }
  .switch_settings-block__1ha9l {
    border: 1px solid #dfe3eb;
    padding: 16px; }
    .switch_settings-block__1ha9l *:last-child {
      margin-bottom: 0; }
  .switch_settings-action__OOZwB {
    background-color: #f4f6f9;
    padding-top: 32px; }
  .switch_settings__19-DN p {
    line-height: 1.2; }

.switch_heading-container__2A7B8 {
  margin: 2pxauto 0;
  max-width: 1124px; }
  .switch_heading-container__2A7B8 p {
    margin-bottom: 0; }

.switch_rules-block__3OJH1 {
  background: rgba(245, 247, 250, 0.5);
  padding: 16px; }
  .switch_rules-block__3OJH1 p {
    margin-bottom: 0; }

.switch_settings-list__1jOFL {
  border: 1px solid #dfe3eb; }

@media (max-width: 480px) {
  .switch_option-label__q1cky {
    margin-right: 0; } }

.checkbox_container__2Mi9R {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
          user-select: none; }
  .checkbox_container__2Mi9R input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.checkbox_checkmark__1ToRj {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 2px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .checkbox_checkmark__1ToRj:after {
    content: "";
    display: none;
    position: absolute; }

.checkbox_container__2Mi9R:hover input ~ .checkbox_checkmark__1ToRj {
  background-color: #f3f3f3; }

.checkbox_container__2Mi9R input:checked ~ .checkbox_checkmark__1ToRj {
  background-color: #36aca3;
  border-color: #36aca3; }
  .checkbox_container__2Mi9R input:checked ~ .checkbox_checkmark__1ToRj:after {
    display: block; }

.checkbox_container__2Mi9R .checkbox_checkmark__1ToRj:after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7153 1.79992L6.78464 0.866592C6.76916 0.85107 6.75076 0.838756 6.73052 0.830354C6.71027 0.821952 6.68856 0.817627 6.66664 0.817627C6.64471 0.817627 6.62301 0.821952 6.60276 0.830354C6.58251 0.838756 6.56412 0.85107 6.54864 0.866592L2.61797 4.79992C2.60249 4.81545 2.5841 4.82776 2.56385 4.83616C2.5436 4.84456 2.52189 4.84889 2.49997 4.84889C2.47805 4.84889 2.45634 4.84456 2.43609 4.83616C2.41584 4.82776 2.39745 4.81545 2.38197 4.79992L1.4513 3.86659C1.43582 3.85107 1.41743 3.83876 1.39718 3.83035C1.37693 3.82195 1.35523 3.81763 1.3333 3.81763C1.31138 3.81763 1.28967 3.82195 1.26943 3.83035C1.24918 3.83876 1.23079 3.85107 1.2153 3.86659L0.284638 4.79992C0.253392 4.83118 0.23584 4.87356 0.23584 4.91776C0.23584 4.96195 0.253392 5.00434 0.284638 5.03559L2.38197 7.13326C2.39745 7.14878 2.41584 7.16109 2.43609 7.1695C2.45634 7.1779 2.47805 7.18222 2.49997 7.18222C2.52189 7.18222 2.5436 7.1779 2.56385 7.1695C2.5841 7.16109 2.60249 7.14878 2.61797 7.13326L7.7153 2.03326C7.74584 2.0021 7.76295 1.96022 7.76295 1.91659C7.76295 1.87297 7.74584 1.83108 7.7153 1.79992Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  left: 3px;
  top: 3px;
  width: 9px; }

.checkbox_input-error__2eUxU {
  border-color: #dc3824;
  color: #dc3824; }

.checkbox_error-block__--zec {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.table_tableresponsive__1TAcl {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table_tableresponsive__1TAcl .table_table__3DkYL {
    width: 100%;
    max-width: 100%; }
    .table_tableresponsive__1TAcl .table_table__3DkYL th {
      color: #1f3041;
      font-weight: 500;
      padding: 21px 16px 12px; }
    .table_tableresponsive__1TAcl .table_table__3DkYL td {
      color: #666;
      padding: 14px 16px; }
    .table_tableresponsive__1TAcl .table_table__3DkYL th,
    .table_tableresponsive__1TAcl .table_table__3DkYL td {
      border-bottom: 1px solid #d3d8dd; }
    .table_tableresponsive__1TAcl .table_table__3DkYL th:last-child,
    .table_tableresponsive__1TAcl .table_table__3DkYL td:last-child {
      text-align: right; }
    .table_tableresponsive__1TAcl .table_table__3DkYL .table_align-center__3Bc9e {
      text-align: center; }
    .table_tableresponsive__1TAcl .table_table__3DkYL tfoot ul {
      display: inline-block;
      margin-bottom: 0; }
      .table_tableresponsive__1TAcl .table_table__3DkYL tfoot ul li {
        margin: 10px;
        cursor: pointer;
        display: inline-block; }
        .table_tableresponsive__1TAcl .table_table__3DkYL tfoot ul li:hover a {
          font-weight: bold; }
        .table_tableresponsive__1TAcl .table_table__3DkYL tfoot ul li a {
          font-size: 16px; }

.table_activePaginate__2-8-S a {
  font-weight: bold; }

.table_tableresponsive__1xA3n {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table_tableresponsive__1xA3n .table_table__3tuCY {
    width: 100%;
    max-width: 100%; }
    .table_tableresponsive__1xA3n .table_table__3tuCY th {
      color: #1f3041;
      font-weight: 500;
      padding: 21px 16px 12px; }
    .table_tableresponsive__1xA3n .table_table__3tuCY td {
      color: #666;
      padding: 14px 16px; }
    .table_tableresponsive__1xA3n .table_table__3tuCY th,
    .table_tableresponsive__1xA3n .table_table__3tuCY td {
      border-bottom: 1px solid #d3d8dd; }
    .table_tableresponsive__1xA3n .table_table__3tuCY th:last-child,
    .table_tableresponsive__1xA3n .table_table__3tuCY td:last-child {
      text-align: right; }
    .table_tableresponsive__1xA3n .table_table__3tuCY .table_align-center__Xs_RB {
      text-align: center; }

.anchorBlock_anchor-block__XYAfz {
  background-color: #fff;
  margin: -1px auto 0;
  max-width: 1280px;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0; }
  @media (max-width: 767px) {
    .anchorBlock_anchor-block__XYAfz {
      overflow-x: scroll; } }
  .anchorBlock_anchor-block__XYAfz > li {
    border-bottom: 4px solid transparent;
    color: #1f3041;
    padding: 16px 3.31% 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 0;
    list-style-type: none; }
    .anchorBlock_anchor-block__XYAfz > li button {
      -webkit-appearance: none;
              appearance: none;
      background-color: #fff;
      border: 0;
      color: #1f3041; }
      .anchorBlock_anchor-block__XYAfz > li button:focus {
        outline: none; }
    .anchorBlock_anchor-block__XYAfz > li.anchorBlock_selected__2sXxn {
      border-bottom-color: #36aca3; }
      .anchorBlock_anchor-block__XYAfz > li.anchorBlock_selected__2sXxn button {
        color: #36aca3;
        text-decoration: none; }

@media (max-width: 920px) {
  .anchorBlock_anchor-block__XYAfz {
    display: none; } }

.sgp--debug-window {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
  background-color: #efefef;
  width: 300px;
  min-height: 150px;
  border-radius: 16px;
  padding: 16px; }
  .sgp--debug-window .sgp--debug-header {
    border-bottom: 1px solid #dedede;
    font-size: 1.2rem;
    font-weight: 400; }
  .sgp--debug-window .sgp--debug-item {
    padding: 15px 0px; }

h3.loaderElement_title__1f0Tr {
  color: #36aca3; }

.loaderElement_toplevel_page_auctollo-index__10xIg #loaderElement_wpbody__2S6V_ {
  position: unset; }
  .loaderElement_toplevel_page_auctollo-index__10xIg #loaderElement_wpbody__2S6V_ .loaderElement_loading-wrapper__Oe5Br {
    margin-top: 10px;
    justify-content: center;
    left: 45%;
    padding-bottom: 20px;
    padding-top: 25px;
    position: absolute; }

.loaderElement_loading-wrapper__Oe5Br {
  margin-top: 10px;
  justify-content: center;
  left: 45%;
  padding-bottom: 20px;
  padding-top: 25px;
  position: absolute; }

.loaderElement_loader-text__17nCy {
  margin-top: 20px;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: .5rem;
  font-weight: 500;
  color: #637381 !important; }

@-webkit-keyframes loaderElement_rotating__32uam {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loaderElement_rotating__32uam {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.loaderElement_rotating__32uam {
  -webkit-animation: loaderElement_rotating__32uam 2s linear infinite;
          animation: loaderElement_rotating__32uam 2s linear infinite;
  z-index: 9; }

.chartDropdown_title__2UGRR {
  font-weight: 500;
  padding-left: 10px;
  cursor: pointer; }
  .chartDropdown_title__2UGRR .chartDropdown_arrow-icon__2NLbF {
    border-color: #637381 transparent transparent;
    border-style: solid;
    border-width: 7px 7px 0;
    display: inline-block;
    height: 0;
    margin-left: 4px;
    width: 0; }
  .chartDropdown_title__2UGRR.chartDropdown_opened__5pDRo .chartDropdown_arrow-icon__2NLbF {
    margin-bottom: 2px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.chartDropdown_chart-dropdown__1JQIU {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1; }
  .chartDropdown_chart-dropdown__1JQIU .chartDropdown_list__EZV6Q {
    border: 1px solid #ececec;
    position: absolute;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .chartDropdown_chart-dropdown__1JQIU .chartDropdown_list-item__199xR {
    -webkit-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    padding: 16px;
    white-space: nowrap;
    width: 100%; }
    .chartDropdown_chart-dropdown__1JQIU .chartDropdown_list-item__199xR:focus {
      outline: none; }
    .chartDropdown_chart-dropdown__1JQIU .chartDropdown_list-item__199xR:hover {
      background-color: #f2f9f7;
      color: #36aca3; }

.chartLegend_chart-legend__3CaI0 {
  color: #666; }

.chartLegend_legend-dot__2jnmR {
  background-color: #637381;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin-right: 8px;
  width: 6px; }

.chartLegend_active__2oezj .chartLegend_legend-dot__2jnmR {
  background-color: #36aca3; }

.toastMessage_toast-message-wrapper__MQ_HR {
  background-color: rgba(220, 56, 36, 0.81);
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  padding: 16px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999; }
  .toastMessage_toast-message-wrapper__MQ_HR .toastMessage_message__2498o {
    margin-bottom: 0;
    padding-left: 10px; }
  .toastMessage_toast-message-wrapper__MQ_HR svg {
    height: 22px;
    width: 17px; }
    .toastMessage_toast-message-wrapper__MQ_HR svg path {
      fill: #fff; }
  .toastMessage_toast-message-wrapper__MQ_HR.toastMessage_success__2JbrC {
    background-color: rgba(54, 172, 163, 0.81); }
  .toastMessage_toast-message-wrapper__MQ_HR.toastMessage_warning__qCfDV {
    background-color: rgba(246, 168, 33, 0.81); }
  .toastMessage_toast-message-wrapper__MQ_HR.toastMessage_error___FBNg {
    background-color: rgba(220, 56, 36, 0.81); }

.radiobutton_container__2rQVq {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
          user-select: none; }
  .radiobutton_container__2rQVq input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.radiobutton_checkmark__1Fhis {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 50%;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .radiobutton_checkmark__1Fhis:after {
    content: "";
    display: none;
    position: absolute; }

.radiobutton_container__2rQVq:hover input ~ .radiobutton_checkmark__1Fhis {
  background-color: #f3f3f3; }

.radiobutton_container__2rQVq input:checked ~ .radiobutton_checkmark__1Fhis {
  background-color: #36aca3;
  border-color: #36aca3; }
  .radiobutton_container__2rQVq input:checked ~ .radiobutton_checkmark__1Fhis:after {
    display: block; }

.radiobutton_input-error__1DeLZ {
  border-color: #dc3824;
  color: #dc3824; }

.radiobutton_error-block__3Vw55 {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.SignUp_label__NAoIZ {
  color: #1f3041;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 8px; }

.SignUp_form-input-group__3EhoR {
  padding-top: 48px; }

.SignUp_social-btn-group__3SaH3 {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 24px;
  margin-left: -5px;
  margin-right: -5px; }
  .SignUp_social-btn-group__3SaH3 .SignUp_form-row__3WiAY:last-child {
    margin-bottom: 0; }

.SignUp_signup-error__FDAn7 {
  margin-bottom: 10px; }

.FormInput_input__1XQcx,
input[type=text],
input[type=email],
input[type=password] {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .FormInput_input__1XQcx:-webkit-autofill, .FormInput_input__1XQcx:-webkit-autofill:hover, .FormInput_input-webkit-autofill__1-XFL:focus, .FormInput_input-webkit-autofill__1-XFL, .FormInput_input-webkit-autofill__1-XFL:hover, .FormInput_input-webkit-autofill__1-XFL:focus, .FormInput_input-webkit-autofill__1-XFL, .FormInput_input-webkit-autofill__1-XFL:hover, .FormInput_input-webkit-autofill__1-XFL:focus,
  input[type=text]:-webkit-autofill,
  input[type=text]:-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=text]-webkit-autofill,
  input[type=text]-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=text]-webkit-autofill,
  input[type=text]-webkit-autofill:hover,
  input[type=text]-webkit-autofill:focus,
  input[type=email]:-webkit-autofill,
  input[type=email]:-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=email]-webkit-autofill,
  input[type=email]-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=email]-webkit-autofill,
  input[type=email]-webkit-autofill:hover,
  input[type=email]-webkit-autofill:focus,
  input[type=password]:-webkit-autofill,
  input[type=password]:-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus,
  input[type=password]-webkit-autofill,
  input[type=password]-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus,
  input[type=password]-webkit-autofill,
  input[type=password]-webkit-autofill:hover,
  input[type=password]-webkit-autofill:focus {
    border: 1px solid #8996a2;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s; }
  .FormInput_input-webkit-input-placeholder__3BVWk,
  input[type=text]-webkit-input-placeholder,
  input[type=email]-webkit-input-placeholder,
  input[type=password]-webkit-input-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .FormInput_input-moz-placeholder__gfRss,
  input[type=text]-moz-placeholder,
  input[type=email]-moz-placeholder,
  input[type=password]-moz-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .FormInput_input-ms-input-placeholder__LP9pM,
  input[type=text]-ms-input-placeholder,
  input[type=email]-ms-input-placeholder,
  input[type=password]-ms-input-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .FormInput_input-moz-placeholder__gfRss,
  input[type=text]-moz-placeholder,
  input[type=email]-moz-placeholder,
  input[type=password]-moz-placeholder {
    color: rgba(99, 115, 129, 0.75); }
  .FormInput_input__1XQcx:focus,
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=password]:focus {
    border-color: #36aca3; }

.FormInput_input-error__vRhPG {
  border-color: #dc3824 !important;
  color: #dc3824 !important; }

.FormInput_error-block__1cAi3 {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.signUpForm_sign-up-form__3DMWx {
  border-radius: 6px;
  margin: 0 auto;
  max-width: 400px;
  padding: 32px 24px 24px; }

.signUpForm_label__1hEew {
  color: #1f3041;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 8px; }

.signUpForm_form-input-group__17zur {
  padding-top: 24px; }

.signUpForm_social-btn-group__wzn3W .signUpForm_form-row__-sMqg:last-child {
  margin-bottom: 0; }

.signUpForm_signup-button__3niOn {
  margin-left: -5px; }

.SignUp_label__2LXJ1 {
  color: #1f3041;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 8px; }

.SignUp_form-input-group__1_gzV {
  padding-top: 48px; }

.SignUp_social-btn-group__Su05i {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 24px;
  margin-left: -5px;
  margin-right: -5px; }
  .SignUp_social-btn-group__Su05i .SignUp_form-row__28Hoq:last-child {
    margin-bottom: 0; }

.SignUp_signup-error__3ynsa {
  margin-bottom: 10px; }

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url(https://app.auctollo.com/static/media/Roboto-Regular.b9077621.eot);
  src: url(https://app.auctollo.com/static/media/Roboto-Regular.b9077621.eot?#iefix) format("embedded-opentype"), url(https://app.auctollo.com/static/media/Roboto-Regular.9feb0110.woff2) format("woff2"), url(https://app.auctollo.com/static/media/Roboto-Regular.94dac78e.woff) format("woff"), url(https://app.auctollo.com/static/media/Roboto-Regular.4312f1fb.ttf) format("truetype"); }

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.auctollo.com/static/media/Roboto-Medium.02aa6e7b.eot);
  src: url(https://app.auctollo.com/static/media/Roboto-Medium.02aa6e7b.eot?#iefix) format("embedded-opentype"), url(https://app.auctollo.com/static/media/Roboto-Medium.50d01d3e.woff2) format("woff2"), url(https://app.auctollo.com/static/media/Roboto-Medium.9a3bf7ac.woff) format("woff"), url(https://app.auctollo.com/static/media/Roboto-Medium.b2bcaa52.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  src: url(https://app.auctollo.com/static/media/Poppins-Regular.e54f6a91.eot);
  src: url(https://app.auctollo.com/static/media/Poppins-Regular.e54f6a91.eot?#iefix) format("embedded-opentype"), url(https://app.auctollo.com/static/media/Poppins-Regular.e02e1902.woff2) format("woff2"), url(https://app.auctollo.com/static/media/Poppins-Regular.1ed9a698.woff) format("woff"), url(https://app.auctollo.com/static/media/Poppins-Regular.b400e683.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.auctollo.com/static/media/Poppins-Medium.dedb4980.eot);
  src: url(https://app.auctollo.com/static/media/Poppins-Medium.dedb4980.eot?#iefix) format("embedded-opentype"), url(https://app.auctollo.com/static/media/Poppins-Medium.2d9db5c9.woff2) format("woff2"), url(https://app.auctollo.com/static/media/Poppins-Medium.896fb06c.woff) format("woff"), url(https://app.auctollo.com/static/media/Poppins-Medium.322761ea.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.auctollo.com/static/media/Poppins-Light.af7b7661.eot);
  src: url(https://app.auctollo.com/static/media/Poppins-Light.af7b7661.eot?#iefix) format("embedded-opentype"), url(https://app.auctollo.com/static/media/Poppins-Light.3a7edf2f.woff2) format("woff2"), url(https://app.auctollo.com/static/media/Poppins-Light.ceaf8c4d.woff) format("woff"), url(https://app.auctollo.com/static/media/Poppins-Light.a67ce206.ttf) format("truetype"); }

* {
  box-sizing: border-box;
  scroll-behavior: smooth; }
  *:focus {
    outline: none; }

#top-iframe-container {
  height: 0px;
  width: 0px;
  visibility: hidden; }
  #top-iframe-container iframe {
    height: 1px !important;
    width: 1px !important; }

body {
  background-color: #f4f6f9 !important;
  color: #637381 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 1.5 !important; }

.list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

h1 {
  color: #1f3041;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5; }

h2 {
  font-size: 24px;
  line-height: 1.2; }

h3 {
  font-size: 20px;
  line-height: 1.5; }

h4 {
  color: #32373c;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5; }

h5 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.625; }

p {
  font-size: 14px;
  line-height: 1.5; }

img {
  height: auto;
  max-width: 100%;
  object-fit: contain; }

a {
  color: #36aca3;
  text-decoration: none; }
  a:hover {
    color: #36aca3;
    text-decoration: underline; }

.app-container {
  min-height: 99vh; }

.container {
  margin: 0 auto;
  max-width: unset;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fixed {
  background-color: #fff;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  width: inherit;
  z-index: 9;
  top: 0; }

.toplevel_page_auctollo-index .update-nag,
.toplevel_page_google-sitemap-generator-premium-index .update-nag {
  display: none; }

.toplevel_page_auctollo-index .selectSet .react-select__value-container,
.toplevel_page_google-sitemap-generator-premium-index .selectSet .react-select__value-container {
  padding: 0;
  margin-top: -4px; }
  @media (max-width: 782px) {
    .toplevel_page_auctollo-index .selectSet .react-select__value-container,
    .toplevel_page_google-sitemap-generator-premium-index .selectSet .react-select__value-container {
      margin-top: -10px; } }
  .toplevel_page_auctollo-index .selectSet .react-select__value-container .react-select__input,
  .toplevel_page_google-sitemap-generator-premium-index .selectSet .react-select__value-container .react-select__input {
    height: auto;
    padding: 0;
    margin-top: 0; }

.App {
  margin: 0 auto;
  width: 100%; }

.welcome-section-wrapper {
  margin: 0 auto;
  max-width: 100%; }

.selectReg .react-select__control {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .selectReg .react-select__control:-webkit-autofill, .selectReg .react-select__control:-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus, .selectReg .react-select__control-webkit-autofill, .selectReg .react-select__control-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus, .selectReg .react-select__control-webkit-autofill, .selectReg .react-select__control-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectReg .react-select__control:focus {
    border-color: #36aca3; }

.selectReg .react-select__control--is-focused {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .selectReg .react-select__control--is-focused:-webkit-autofill, .selectReg .react-select__control--is-focused:-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus, .selectReg .react-select__control--is-focused-webkit-autofill, .selectReg .react-select__control--is-focused-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus, .selectReg .react-select__control--is-focused-webkit-autofill, .selectReg .react-select__control--is-focused-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectReg .react-select__control--is-focused:focus {
    border-color: #36aca3 !important; }

.selectSet .react-select__control--is-focused {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  padding: 4px 16px;
  width: 152px; }
  .selectSet .react-select__control--is-focused:-webkit-autofill, .selectSet .react-select__control--is-focused:-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus, .selectSet .react-select__control--is-focused-webkit-autofill, .selectSet .react-select__control--is-focused-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus, .selectSet .react-select__control--is-focused-webkit-autofill, .selectSet .react-select__control--is-focused-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectSet .react-select__control--is-focused:focus {
    border-color: #36aca3 !important; }

.selectSet .react-select__control {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  padding: 4px 16px;
  width: 152px; }
  .selectSet .react-select__control:-webkit-autofill, .selectSet .react-select__control:-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus, .selectSet .react-select__control-webkit-autofill, .selectSet .react-select__control-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus, .selectSet .react-select__control-webkit-autofill, .selectSet .react-select__control-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectSet .react-select__control:focus {
    border-color: #36aca3 !important; }

.settings-table .react-select__control {
  border: 0 !important; }

.settings-table .style-input {
  border: 0 !important;
  height: 40px; }

.react-select__indicator-separator,
.react-select__indicators {
  display: none !important; }

.customeSelect .react-select__value-container {
  margin-left: 0;
  padding-left: 0;
  height: 30px; }

.select-error .react-select__control {
  border-color: #dc3824 !important;
  color: #dc3824; }

.error-block {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

@media (min-width: 768px) {
  .welcome-section-wrapper {
    max-width: 100%; } }

@media (min-width: 992px) {
  .fixed {
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 0;
    width: 100%; }
  .welcome-section-wrapper {
    max-width: 100%; } }

@media (min-width: 1330px) {
  .fixed {
    left: 0;
    max-width: 100%;
    right: 0;
    margin: auto; }
  .welcome-section-wrapper {
    max-width: 100%; } }

@media (min-width: 1025px) and (max-width: 1329px) {
  .fixed {
    max-width: 100%;
    margin: 0;
    background: transparent;
    width: 100%; }
  .welcome-section-wrapper {
    max-width: 100%; } }

@media (min-width: 1400px) {
  .toplevel_page_auctollo-index .update-nag,
  .toplevel_page_google-sitemap-generator-premium-index .update-nag {
    display: none; }
  .fixed {
    left: 0;
    max-width: 100%;
    right: 0;
    margin: auto; }
  .welcome-section-wrapper {
    max-width: 100%; } }

@media (max-width: 767px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 18px; }
  h4 {
    font-size: 16px; }
  h5 {
    font-size: 15px; }
  .fixed {
    width: 100%;
    max-width: 100%;
    position: relative; }
  .toplevel_page_auctollo-index.wp-admin .nav-menu,
  .toplevel_page_google-sitemap-generator-premium-index.wp-admin .nav-menu {
    right: -9px; }
  .toplevel_page_auctollo-index.folded .nav-menu,
  .toplevel_page_google-sitemap-generator-premium-index.folded .nav-menu {
    right: -9px; }
  .toplevel_page_auctollo-index .react-select__menu *,
  .toplevel_page_google-sitemap-generator-premium-index .react-select__menu * {
    cursor: pointer; }
  .toplevel_page_auctollo-index .react-select__control,
  .toplevel_page_google-sitemap-generator-premium-index .react-select__control {
    cursor: pointer !important; }
  .welcome-section-wrapper {
    padding-top: 0; } }

@media (max-width: 480px) {
  .welcome-section-wrapper {
    margin: 0; }
  p {
    line-height: 1.2; } }

.signInForm_form-input-group .error-block {
  margin-bottom: 16px; }

.react-select__menu * {
  cursor: pointer; }

.react-select__control {
  cursor: pointer !important; }

.selectSet .css-b8ldur-Input {
  margin: 0; }
  .selectSet .css-b8ldur-Input input {
    height: 26px; }
    .selectSet .css-b8ldur-Input input:focus {
      outline: none;
      box-shadow: none; }

.setting-content-wrapper .checkbox-design {
  padding: 16px 1px; }

.setting-content-wrapper .link-wrapper {
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .setting-content-wrapper .link-wrapper .input-wrap {
    text-align: right; }
    .setting-content-wrapper .link-wrapper .input-wrap input {
      max-width: 50%; }
      @media (max-width: 767px) {
        .setting-content-wrapper .link-wrapper .input-wrap input {
          max-width: 100%; } }

.content-table-wrap {
  border-bottom: none !important; }
  @media (max-width: 767px) {
    .content-table-wrap .flex-column {
      min-width: 200px;
      border-bottom: 1px solid #dfe3eb;
      width: 100% !important; }
      .content-table-wrap .flex-column:nth-child(2) {
        border: none; } }
  .content-table-wrap .table-wrap .d-flex {
    flex-wrap: unset; }
  @media (max-width: 768px) {
    .content-table-wrap .table-wrap {
      overflow-x: auto; } }
  .content-table-wrap .table-wrap .react-select__control {
    background-size: 10px 6px, 7px 8px, 1px 1.5em; }

.setting-priority-wrapper .checkbox-design {
  padding: 16px 3px; }

.setting-priority-wrapper .first-priority-section .radio-wrap {
  padding-right: 0; }
  .setting-priority-wrapper .first-priority-section .radio-wrap label {
    padding-left: 26px; }
    .setting-priority-wrapper .first-priority-section .radio-wrap label span {
      border-radius: 3px;
      width: 24px;
      height: 24px; }
      .setting-priority-wrapper .first-priority-section .radio-wrap label span:after {
        height: 12px;
        left: 0;
        top: 0;
        width: 12px;
        right: 0;
        margin: auto;
        bottom: 0; }

.setting-priority-wrapper .priority-section .flex-column p {
  line-height: normal;
  margin-bottom: 20px; }
  .setting-priority-wrapper .priority-section .flex-column p:last-child {
    margin-bottom: 0; }

.setting-priority-wrapper .priority-section .right-select {
  padding-right: 8px; }

.content-form-wrap {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative; }
  @media (max-width: 767px) {
    .content-form-wrap {
      display: block; } }
  .content-form-wrap:after {
    content: "";
    position: absolute;
    border-bottom: 2px solid #dfe3eb;
    left: -20px;
    right: -20px;
    bottom: 0;
    width: calc(100% + 40px); }
  .content-form-wrap .name-label,
  .content-form-wrap .email-label {
    width: 50%;
    max-width: 30%; }
    @media (max-width: 767px) {
      .content-form-wrap .name-label,
      .content-form-wrap .email-label {
        max-width: 100%;
        width: 100%; } }
  .content-form-wrap .name-label {
    margin-right: 30px; }
    @media (max-width: 767px) {
      .content-form-wrap .name-label {
        margin-bottom: 10px; } }

.payment-model-dialog.modal-dialog .modal-content .d-flex {
  padding-top: 0;
  padding-bottom: 0; }

.payment-model-dialog.modal-dialog .button-close {
  text-align: right;
  padding: 10px;
  padding-bottom: 0;
  cursor: pointer; }

.payment-model-dialog.modal-dialog .modal-body {
  padding: 20px;
  padding-top: 3px;
  padding-bottom: 25px; }
  .payment-model-dialog.modal-dialog .modal-body p {
    margin-bottom: 10px; }
  .payment-model-dialog.modal-dialog .modal-body input {
    margin-bottom: 15px !important; }

.btn-left {
  justify-content: flex-start !important; }
  @media (min-width: 767px) {
    .btn-left {
      padding-left: 10px; } }

.btn-add-method {
  padding: 15px 30px; }

h5.title {
  color: #32373c;
  margin-bottom: 16px; }

.configuration-wrapper div div {
  letter-spacing: 0.5px; }

.chart-slider-wrapper {
  background-color: #fff;
  display: none;
  margin-top: 20px; }
  .chart-slider-wrapper .slick-arrow {
    top: 33.4%;
    z-index: 9; }
    .chart-slider-wrapper .slick-arrow:before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12L5 7L0 2L1 0L8 7L1 14L0 12Z' fill='%2336ACA3'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-flex;
      height: 14px;
      width: 8px; }
  .chart-slider-wrapper .slick-next {
    right: 4px; }
  .chart-slider-wrapper .slick-prev {
    left: 4px; }
    .chart-slider-wrapper .slick-prev:before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .chart-slider-wrapper .top-slider .chart {
    padding: 12px 40px; }
  .chart-slider-wrapper .slider-nav .slick-slide {
    border-top: 4px solid #fff;
    font-size: 16px;
    padding: 16px 0;
    text-align: center; }
    .chart-slider-wrapper .slider-nav .slick-slide.slick-current {
      border-color: #36aca3;
      color: #36aca3; }
  .chart-slider-wrapper .slider-nav .slick-list {
    margin: 0 auto;
    width: 85%; }
  .chart-slider-wrapper .slider-nav .slick-slider {
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15); }
  .chart-slider-wrapper .slider-nav .slick-arrow {
    top: 48%; }
  .chart-slider-wrapper .chart-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px; }
    .chart-slider-wrapper .chart-title .value {
      padding-left: 4px; }
  .chart-slider-wrapper .value {
    color: #4c5862;
    font-size: 12px; }
  .chart-slider-wrapper .label {
    color: #9ea3a8;
    font-size: 12px; }

.chart-content-list {
  display: flex;
  justify-content: space-between; }
  .chart-content-list .list-item {
    display: flex;
    flex-direction: column; }
    .chart-content-list .list-item .value {
      font-size: 16px; }

.chart-details {
  padding: 0 16px 16px; }

@media (max-width: 480px) {
  .chart-slider-wrapper {
    display: block; } }

.top-notifications {
  text-align: center; }

.toplevel_page_google-sitemap-generator-premium-index {
  /*---Setting page---*/ }
  .toplevel_page_google-sitemap-generator-premium-index.folded .fixed {
    left: 0;
    width: 100%; }
  .toplevel_page_google-sitemap-generator-premium-index.folded #wpcontent {
    width: calc(100% - 36px);
    overflow: hidden; }
    @media (max-width: 768px) {
      .toplevel_page_google-sitemap-generator-premium-index.folded #wpcontent {
        width: 100%;
        overflow: hidden; } }
  .toplevel_page_google-sitemap-generator-premium-index.folded .settings-page ul {
    width: calc(100% - 36px);
    left: 36px !important; }
    @media (min-width: 961px) {
      .toplevel_page_google-sitemap-generator-premium-index.folded .settings-page ul {
        left: 36px !important;
        width: calc(100% - 36px); } }
  .toplevel_page_google-sitemap-generator-premium-index .chart-slider-wrapper {
    display: none !important; }
  .toplevel_page_google-sitemap-generator-premium-index #top-iframe-container {
    position: absolute;
    opacity: 0;
    z-index: -9; }
  .toplevel_page_google-sitemap-generator-premium-index #wpcontent {
    padding-left: 0;
    width: calc(100% - 160px);
    overflow: hidden; }
    @media (max-width: 960px) {
      .toplevel_page_google-sitemap-generator-premium-index #wpcontent {
        width: 100%;
        left: 0;
        max-width: 100%; } }
    @media (min-width: 768px) and (max-width: 782px) {
      .toplevel_page_google-sitemap-generator-premium-index #wpcontent {
        width: 100%; } }
    @media (max-width: 767px) {
      .toplevel_page_google-sitemap-generator-premium-index #wpcontent {
        width: 100%; } }
  .toplevel_page_google-sitemap-generator-premium-index .container {
    max-width: 100%;
    padding: 0; }
  .toplevel_page_google-sitemap-generator-premium-index .fixed {
    width: 100%;
    left: 0 !important;
    max-width: 100% !important;
    position: -webkit-sticky !important;
    position: sticky !important; }
    @media (min-width: 961px) and (max-width: 1400px) {
      .toplevel_page_google-sitemap-generator-premium-index .fixed {
        max-width: 100% !important;
        margin: 0; } }
  .toplevel_page_google-sitemap-generator-premium-index .welcome-section-wrapper {
    max-width: 100%;
    width: 100%; }
    .toplevel_page_google-sitemap-generator-premium-index .welcome-section-wrapper a {
      color: #36aca3 !important; }
  .toplevel_page_google-sitemap-generator-premium-index .section-wrapper {
    max-width: 100%;
    overflow: hidden; }
    .toplevel_page_google-sitemap-generator-premium-index .section-wrapper > div {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 100%;
      overflow: visible !important; }
  .toplevel_page_google-sitemap-generator-premium-index .setting-fixed-title {
    max-width: 100%;
    margin: 0 15px; }
  .toplevel_page_google-sitemap-generator-premium-index .tabs {
    max-width: 100%;
    display: inline-block; }
    @media (max-width: 767px) {
      .toplevel_page_google-sitemap-generator-premium-index .tabs {
        display: block;
        margin: 0; }
        .toplevel_page_google-sitemap-generator-premium-index .tabs li {
          width: 100%; } }
    .toplevel_page_google-sitemap-generator-premium-index .tabs li {
      margin-bottom: 0;
      display: inline-block;
      vertical-align: bottom; }
  .toplevel_page_google-sitemap-generator-premium-index .settings-container {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px; }
  .toplevel_page_google-sitemap-generator-premium-index .wp-section {
    max-width: 100%; }
    .toplevel_page_google-sitemap-generator-premium-index .wp-section ul.list {
      width: 100%;
      left: 0;
      max-width: 100%;
      box-shadow: inset 0px 1px 0px #f3f3f4;
      top: 0; }
      @media (max-width: 767px) {
        .toplevel_page_google-sitemap-generator-premium-index .wp-section ul.list {
          overflow-x: scroll;
          display: flex !important;
          justify-content: inherit; } }
      .toplevel_page_google-sitemap-generator-premium-index .wp-section ul.list li {
        margin-bottom: 0;
        display: inline-flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
  @media (min-width: 1330px) {
    .toplevel_page_google-sitemap-generator-premium-index .wp-login-notify {
      max-width: 100%; } }
  .toplevel_page_google-sitemap-generator-premium-index input[type="checkbox"]:disabled {
    opacity: 0 !important; }
  .toplevel_page_google-sitemap-generator-premium-index .selectSet .react-select__value-container {
    position: unset; }
    .toplevel_page_google-sitemap-generator-premium-index .selectSet .react-select__value-container input {
      box-shadow: none !important;
      line-height: inherit !important;
      min-height: inherit !important;
      height: auto; }
  .toplevel_page_google-sitemap-generator-premium-index .wp-loader {
    right: 0;
    left: 160px;
    -webkit-transform: none;
            transform: none;
    top: 37%; }
  .toplevel_page_google-sitemap-generator-premium-index .settings-tab ul {
    box-shadow: none !important; }
  .toplevel_page_google-sitemap-generator-premium-index .container.app-container a {
    color: #36aca3 !important; }

.response_msg {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #f4f6f9;
  padding: 15px 30px;
  color: #000;
  margin: -60px 0 0;
  position: relative; }

.response_msg p {
  margin: 0; }

.wp-settings-option > div {
  max-width: 695px; }

.wp-settings-option .option-left {
  max-width: 152px; }

.buttonPrimary_button {
  max-height: 48px; }

.setting-priority-wrapper .first-priority-section .wp-post-priority {
  position: relative; }
  .setting-priority-wrapper .first-priority-section .wp-post-priority .radio-wrap {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: auto; }
    .setting-priority-wrapper .first-priority-section .wp-post-priority .radio-wrap label {
      padding: 0;
      margin: 0;
      width: 25px;
      height: 25px;
      display: inline-block; }
      .setting-priority-wrapper .first-priority-section .wp-post-priority .radio-wrap label span {
        position: unset;
        margin: 0;
        padding: 0;
        display: inline-block; }

.configuration-wrapper .wp-setting-option-sm .wp-option-right {
  width: auto !important; }

.configuration-wrapper .wp-setting-option-sm input {
  max-width: 152px; }

.wp-primary-btn button {
  padding: 16px 16px; }

.wp-heading-weg {
  padding-bottom: 30px; }
  .wp-heading-weg h1 {
    font-weight: 300 !important;
    margin-bottom: 6px; }
  .wp-heading-weg p {
    margin-top: 0; }

.bing-webmaster {
  min-height: 300px; }
  .bing-webmaster .bing-section {
    height: 270px; }

/* Configration Tab */
@media (max-width: 767px) {
  .settings-tab {
    overflow: scroll;
    white-space: nowrap;
    margin-bottom: 20px; } }

.settings-tab ul {
  margin-bottom: 8px; }
  .settings-tab ul li {
    padding: 0 25px 10px;
    border-width: 2px; }

.custom-inputbox input {
  height: 40px; }

.custom-inputbox input::-webkit-input-placeholder {
  opacity: 50%; }

.custom-inputbox input::placeholder {
  opacity: 50%; }

/* Content Tab */
.content-text p {
  margin-bottom: 20px; }

.content-text .content-table-wrap .table-head .flex-column > div {
  font-weight: 500;
  color: #1f3041;
  padding: 7px 16px; }

.contect-second-section .sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 12px; }

/* Subscription Tab Modal */
.subscription-modal .modal-head {
  padding: 0 16px !important; }
  .subscription-modal .modal-head strong {
    color: #000000;
    font-size: 20px !important;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 300 !important; }

.subscription-modal .modal-body {
  padding: 0 16px 10px !important; }
  .subscription-modal .modal-body > p {
    margin-bottom: 16px; }
  .subscription-modal .modal-body label {
    font-size: 12px; }
  .subscription-modal .modal-body .submit-btn {
    margin-top: 8px; }
    .subscription-modal .modal-body .submit-btn button:focus {
      outline: none; }

.subscription-modal .error-msg {
  margin-top: -10px; }

.subscription-modal .date-field {
  margin-bottom: 15px; }

@media (max-width: 767px) {
  .configuration-content p {
    margin-bottom: 20px !important;
    white-space: normal;
    word-break: break-all; }
  .configuration-text {
    white-space: normal;
    align-items: self-start; }
    .configuration-text .content-box {
      margin-bottom: 10px;
      padding-right: 10px !important; }
  .section-block {
    display: block !important; }
  .section-content {
    white-space: normal; }
  .section-left {
    align-items: flex-start !important;
    margin-top: 10px; }
    .section-left button {
      margin-left: 0 !important; }
  .wp-post-priority .content-box {
    margin-right: 30px !important; }
  .wp-post-priority .radio-wrap {
    right: 15px !important; }
  .section-payment {
    word-break: break-all; }
    .section-payment .bg-white {
      display: block !important; }
      .section-payment .bg-white div + .d-flex {
        margin-left: -15px; } }

.custom-modal {
  max-width: 800px;
  position: relative; }
  .custom-modal .modal-content {
    padding-top: 24px !important; }
    @media (min-width: 767px) {
      .custom-modal .modal-content {
        padding: 40px 15px 20px; } }
    .custom-modal .modal-content .button-close {
      position: absolute;
      right: 30px; }

.subscription-modal {
  max-width: 600px; }
  .subscription-modal .modal-content {
    padding: 15px; }

