@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Roboto/Roboto-Regular.eot");
    src:
        url("../fonts/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
        url("../fonts/Roboto/Roboto-Regular.woff") format("woff"),
        url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Roboto/Roboto-Medium.eot");
    src:
        url("../fonts/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
        url("../fonts/Roboto/Roboto-Medium.woff") format("woff"),
        url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Poppins/Poppins-Regular.eot");
    src:
        url("../fonts/Poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
        url("../fonts/Poppins/Poppins-Regular.woff") format("woff"),
        url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Poppins/Poppins-Medium.eot");
    src:
        url("../fonts/Poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Poppins/Poppins-Medium.woff2") format("woff2"),
        url("../fonts/Poppins/Poppins-Medium.woff") format("woff"),
        url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Poppins/Poppins-Light.eot");
    src:
        url("../fonts/Poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Poppins/Poppins-Light.woff2") format("woff2"),
        url("../fonts/Poppins/Poppins-Light.woff") format("woff"),
        url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
}
