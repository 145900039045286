@import "../../assets/scss/variables";

.sign-up-form {
    border-radius: $base_border_radius * 2;
    margin: 0 auto;
    max-width: 400px;
    padding: 32px 24px 24px;
}

.label {
    color: $dark_text_color;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 8px;
}

.form-input-group {
    padding-top: 24px;
}

.social-btn-group {

    .form-row {

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.signup-button{
    margin-left: -5px;
}