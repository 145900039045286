@import "./variables";

* {
  box-sizing: border-box;
  scroll-behavior: smooth;

  &:focus {
    outline: none;
  }
}

#top-iframe-container {
  height: 0px;
  width: 0px;
  visibility: hidden;

  iframe {
    height: 1px !important;
    width: 1px !important;
  }
}

body {
  background-color: $body-bg-color !important;
  color: $light-text-color !important;
  font-family: $base-font-family !important;
  font-size: $base-font-size !important;
  font-weight: normal !important;
  line-height: $base-line-height !important;
}

.list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

h1 {
  color: $dark-text-color;
  font-size: 28px;
  font-weight: 300;
  line-height: $base-line-height;
}

h2 {
  font-size: 24px;
  line-height: 1.2;
}

h3 {
  font-size: 20px;
  line-height: $base-line-height;
}

h4 {
  color: $darkest-text-color;
  font-size: 18px;
  font-weight: 500;
  line-height: $base-line-height;
}

h5 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.625;
}

p {
  font-size: $base-font-size;
  line-height: $base-line-height;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

a {
  color: $color_primary;
  text-decoration: none;

  &:hover {
    color: $color_primary;
    text-decoration: underline;
  }
}

.app-container {
  min-height: 99vh;
}

.container {
  margin: 0 auto;
  max-width: unset;
  overflow: hidden;
  padding: 0;
  // padding: 0 20px;
  width: 100%;
}

.fixed {
  background-color: #fff;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  // max-width: 1280px;
  position: sticky;
  right: 0;
  width: inherit;
  z-index: 9;
  top: 0;
}

.toplevel_page_auctollo-index,
.toplevel_page_google-sitemap-generator-premium-index {
  .update-nag {
    display: none;
  }

  .selectSet {
    .react-select__value-container {
      padding: 0;
      margin-top: -4px;

      @media (max-width: 782px) {
        margin-top: -10px;
      }

      .react-select__input {
        height: auto;
        padding: 0;
        margin-top: 0;
      }
    }
  }
}

.App {
  margin: 0 auto;
  width: 100%;
}

.welcome-section-wrapper {
  margin: 0 auto;
  // max-width: 1280px;
  max-width: 100%;
}

//CustomeSelect

.selectReg {
  .react-select__control {
    appearance: none;
    background-color: #fff;
    background-image: linear-gradient(45deg,
        transparent 50%,
        $light_text_color 50%),
      linear-gradient(135deg, $light_text_color 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 8px),
      calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    border: 1px solid #8996a2 !important;
    border-radius: $base_border_radius * 2 !important;
    color: $light_text_color;
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    padding: 8px 16px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
      border: 1px solid #8996a2 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:focus {
      border-color: $color_primary;
    }
  }
}

.selectReg {
  .react-select__control--is-focused {
    appearance: none;
    background-color: #fff !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        $light_text_color 50%),
      linear-gradient(135deg, $light_text_color 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 8px),
      calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    border: 1px solid #8996a2 !important;
    border-radius: $base_border_radius * 2 !important;
    box-shadow: none !important;
    color: $light_text_color !important;
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    padding: 8px 16px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
      border: 1px solid #8996a2 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:focus {
      border-color: $color_primary !important;
    }
  }
}

.selectSet {
  .react-select__control--is-focused {
    appearance: none;
    background-color: #fff !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        $light_text_color 50%),
      linear-gradient(135deg, $light_text_color 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 4px),
      calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    border: 1px solid #8996a2 !important;
    border-radius: $base_border_radius * 2 !important;
    box-shadow: none !important;
    color: $light_text_color !important;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    padding: 4px 16px;
    width: 152px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
      border: 1px solid #8996a2 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:focus {
      border-color: $color_primary !important;
    }
  }
}

.selectSet {
  .react-select__control {
    appearance: none;
    background-color: #fff !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        $light_text_color 50%),
      linear-gradient(135deg, $light_text_color 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 4px),
      calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    border: 1px solid #8996a2 !important;
    border-radius: $base_border_radius * 2 !important;
    box-shadow: none !important;
    color: $light_text_color !important;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    padding: 4px 16px;
    width: 152px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
      border: 1px solid #8996a2 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:focus {
      border-color: $color_primary !important;
    }
  }
}

.settings-table {
  .react-select__control {
    border: 0 !important;
  }

  .style-input {
    border: 0 !important;
    height: 40px;
  }
}

.react-select__indicator-separator,
.react-select__indicators {
  display: none !important;
}

.customeSelect .react-select__value-container {
  margin-left: 0;
  padding-left: 0;
  height: 30px;
}

.select-error .react-select__control {
  border-color: $color_error !important;
  color: $color_error;
}

.error-block {
  color: $color_error;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

@media (max-width: 920px) {}

@media (min-width: 768px) {
  .welcome-section-wrapper {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .fixed {
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 0;
    width: 100%;
  }

  .welcome-section-wrapper {
    max-width: 100%;
  }
}

@media (min-width: 1330px) {
  .fixed {
    left: 0;
    max-width: 100%;
    // max-width: 1280px;
    right: 0;
    margin: auto;
  }

  .welcome-section-wrapper {
    // max-width: 1280px;
    max-width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1329px) {
  .fixed {
    max-width: 100%;
    margin: 0;
    background: transparent;
    width: 100%;
  }

  .welcome-section-wrapper {
    max-width: 100%;
  }
}

@media (min-width: 1400px) {

  .toplevel_page_auctollo-index,
  .toplevel_page_google-sitemap-generator-premium-index {
    .update-nag {
      display: none;
    }
  }

  .fixed {
    left: 0;
    max-width: 100%;
    // max-width: 1280px;
    right: 0;
    margin: auto;
  }

  .welcome-section-wrapper {
    // max-width: 1280px;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 15px;
  }

  .container {
    // padding: 0 10px;
  }

  .fixed {
    width: 100%;
    max-width: 100%;
    position: relative;
  }

  .toplevel_page_auctollo-index,
  .toplevel_page_google-sitemap-generator-premium-index {
    &.wp-admin {
      .nav-menu {
        right: -9px;
      }
    }

    &.folded {
      .nav-menu {
        right: -9px;
      }
    }

    .react-select__menu * {
      cursor: pointer;
    }

    .react-select__control {
      cursor: pointer !important;
    }
  }

  .welcome-section-wrapper {
    padding-top: 0;
  }
}

@media (max-width: 480px) {
  .welcome-section-wrapper {
    margin: 0;
  }

  p {
    line-height: 1.2;
  }
}

.signInForm_form-input-group {
  .error-block {
    margin-bottom: 16px;
  }
}

.react-select__menu * {
  cursor: pointer;
}

.react-select__control {
  cursor: pointer !important;
}

// design fixes
.selectSet {
  .css-b8ldur-Input {
    margin: 0;

    input {
      height: 26px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.setting-content-wrapper {
  .checkbox-design {
    padding: 16px 1px;
  }

  .contect-first-section {
    button {
      // padding-right: 30px;
    }
  }

  .link-wrapper {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-wrap {
      text-align: right;

      input {
        max-width: 50%;

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
    }
  }
}

.content-table-wrap {
  border-bottom: none !important;

  .flex-column {
    @media (max-width: 767px) {
      min-width: 200px;
      border-bottom: 1px solid #dfe3eb;
      width: 100% !important;

      &:nth-child(2) {
        border: none;
      }
    }
  }

  .table-wrap {
    .d-flex {
      flex-wrap: unset;
    }

    @media (max-width: 768px) {
      overflow-x: auto;
    }

    .react-select__control {
      background-size: 10px 6px, 7px 8px, 1px 1.5em;
    }
  }
}

.setting-priority-wrapper {
  .checkbox-design {
    padding: 16px 3px;
  }

  .first-priority-section {
    .radio-wrap {
      padding-right: 0;

      label {
        padding-left: 26px;

        span {
          border-radius: 3px;
          width: 24px;
          height: 24px;

          &:after {
            height: 12px;
            left: 0;
            top: 0;
            width: 12px;
            right: 0;
            margin: auto;
            bottom: 0;
          }
        }
      }
    }
  }

  .priority-section {
    .flex-column {
      p {
        line-height: normal;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .right-select {
      padding-right: 8px;
    }
  }
}

.content-form-wrap {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;

  @media (max-width: 767px) {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    border-bottom: 2px solid #dfe3eb;
    left: -20px;
    right: -20px;
    bottom: 0;
    width: calc(100% + 40px);
  }

  .name-label,
  .email-label {
    width: 50%;
    max-width: 30%;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .name-label {
    margin-right: 30px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}

.payment-model-dialog.modal-dialog {
  .modal-content {
    .d-flex {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .button-close {
    text-align: right;
    padding: 10px;
    padding-bottom: 0;
    cursor: pointer;
  }

  .modal-body {
    padding: 20px;
    padding-top: 3px;
    padding-bottom: 25px;

    p {
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 15px !important;
    }
  }
}

.btn-left {
  justify-content: flex-start !important;

  @media (min-width: 767px) {
    padding-left: 10px;
  }
}

.btn-add-method {
  padding: 15px 30px;
}

h5.title {
  color: #32373c;
  margin-bottom: 16px;
}

.configuration-wrapper {
  div div {
    letter-spacing: 0.5px;
  }
}