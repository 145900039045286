.sgp--debug-window {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
  background-color: #efefef;
  width: 300px;
  min-height: 150px;
  border-radius: 16px;
  padding: 16px;

  .sgp--debug-header{
    border-bottom: 1px solid #dedede;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .sgp--debug-item{
    padding: 15px 0px;
  }

}