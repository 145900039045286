@import "../../../assets/scss/variables";

.switch-container {
    cursor: pointer;
    padding: 4px;
    position: relative;
}

.switch {
    opacity: 0;
    position: absolute;

    + div {
        background-color: #f3f3f3;
        border-radius: 999px;
        cursor: pointer;
        transition-duration: 0.3s;
        transition-property: background-color, box-shadow;
        vertical-align: middle;
    }

    &:checked + div {
        background-color: #3b89ec;
        width: 48px;
    }

    + div {
        height: 16px;
        width: 48px;
    }

    &:checked + div {
        background-color: rgba(54, 172, 163, 0.3);
    }

    + div > div {
        background: #d3d8dd;
        border-radius: inherit;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        height: 24px;
        margin-left: 1px;
        margin-top: 1px;
        pointer-events: none;
        position: relative;
        top: -3px;
        transition-duration: 0.3s;
        transition-property: transform, background-color;
        transition-timing-function: ease-in-out;
        width: 24px;
    }

    &:checked {

        + div > div {
            background-color: $color_primary;
            transform: translate3d(22px, 0, 0);
        }
    }
}
