@import "./variables";

.bing-webmaster {
  min-height: 300px;

  .bing-section {
    height: 270px;
  }
}

/* Configration Tab */
.settings-tab {
  @media (max-width: 767px) {
    overflow: scroll;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 8px;

    li {
      padding: 0 25px 10px;
      border-width: 2px;
    }
  }

}

.custom-inputbox {
  input {
    height: 40px;

  }

  input::placeholder {
    opacity: 50%;
  }
}

/* Content Tab */
.content-text {
  p {
    margin-bottom: 20px;
  }

  .content-table-wrap {
    .table-head {
      .flex-column>div {
        font-weight: 500;
        color: $dark_text_color;
        padding: 7px 16px;
      }
    }
  }
}

.contect-second-section {
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }
}

/* Subscription Tab Modal */
.subscription-modal {
  .modal-head {
    padding: 0 16px !important;

    strong {
      color: #000000;
      font-size: 20px !important;
      line-height: 30px;
      margin-bottom: 10px;
      font-weight: 300 !important;
    }
  }

  .modal-body {
    padding: 0 16px 10px !important;

    >p {
      margin-bottom: 16px;
    }

    label {
      font-size: 12px;
    }

    .submit-btn {
      margin-top: 8px;

      button:focus {
        outline: none;
      }
    }

  }

  .error-msg {
    margin-top: -10px;
  }

  .date-field {
    margin-bottom: 15px;
  }

}


@media (max-width: 767px) {
  .configuration-content {
    p {
      margin-bottom: 20px !important;
      white-space: normal;
      word-break: break-all;
    }
  }

  .configuration-text {
    white-space: normal;
    align-items: self-start;

    .content-box {
      margin-bottom: 10px;
      padding-right: 10px !important;
    }
  }

  .section-block {
    display: block !important;
  }

  .section-content {
    white-space: normal;
  }

  .section-left {
    align-items: flex-start !important;
    margin-top: 10px;

    button {
      margin-left: 0 !important;
    }
  }

  .wp-post-priority {

    .content-box {
      margin-right: 30px !important;
    }

    .radio-wrap {
      right: 15px !important;
    }
  }

  .section-payment {
    word-break: break-all;

    .bg-white {
      display: block !important;

      div+.d-flex {
        margin-left: -15px;
      }
    }

  }

}

.custom-modal {
  max-width: 800px;
  position: relative;

  .modal-content {
    padding-top: 24px !important;

    @media (min-width: 767px) {
      padding: 40px 15px 20px;
    }

    .button-close {
      position: absolute;
      right: 30px;
    }
  }
}

.subscription-modal {
  max-width: 600px;

  .modal-content {
    padding: 15px;
  }

}