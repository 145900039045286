@import "../../../assets/scss/variables";

.heading {
    padding: 0 32px;

    &.center {
        text-align: center;

        .subtitle {
            margin-left: auto;
            margin-right: auto;
            max-width: 345px;
        }
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

h1.title {
    line-height: 1.27;
    // margin: 12px 0 0;
}

@media (max-width: 480px) {

    .heading {
        padding-bottom: 0;
    }
}


/*-------- Datepicker Design ---------*/
.datepickerWrapper {
    display: flex;
    align-items: center;

    .datewrapper {
        position: relative;
        cursor: pointer;

        input {
            border-radius: 25px;
            border: 1px solid $light_text_color;
            padding: 5px 12px 4px 40px;
            position: relative;
            color: $light_text_color;
            font-size: 14px;
            line-height: 21px;
            width: 140px;
            cursor: pointer;
        }

        &:after {
            content: "";
            position: absolute;
            left: 16px;
            top: 0;
            bottom: 0;
            background: url("assets/img/calender.svg");
            height: 16px;
            width: 14px;
            margin: auto;
        }
    }

    .arrow {
        margin: 0 8px;
    }
}

@media (max-width: 340px) {
    .datepickerWrapper {
        display: inline-block;
        width: 100%;
    }
}