@import "../../../assets/scss/variables";

.select {
    appearance: none;
    background-color: transparent;
    background-image: linear-gradient(45deg, transparent 50%, $light_text_color 50%), linear-gradient(135deg, $light_text_color 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    border: 1px solid #8996a2;
    border-radius: 6px;
    box-sizing: border-box;
    height: 48px;
    margin: 0;
    min-width: 152px;
    padding: 8px 16px;
}
