@import "../../../assets/scss/variables";

.modal-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.modal-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    overflow: auto;
    padding: 20px;
    width: 30%;
    z-index: 10;
}

.get-started-link {
    align-self: flex-start;
    background-color: $color_primary;
    border: 1px solid $color_primary;
    border-radius: $base_border_radius * 2;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    color: #fff;
    padding: 7.3px 16px;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    transition: all 0.2s linear;

    &:hover {
        background-color: $color_primary_hover;
        border-color: $color_primary_hover;
        color: #fff;
        text-decoration: none;
    }
}