@import "./variables";

.chart-slider-wrapper {
    background-color: #fff;
    display: none;
    margin-top: 20px;

    .slick-arrow {
        top: 33.4%;
        z-index: 9;

        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12L5 7L0 2L1 0L8 7L1 14L0 12Z' fill='%2336ACA3'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            display: inline-flex;
            height: 14px;
            width: 8px;
        }
    }

    .slick-next {
        right: 4px;
    }

    .slick-prev {
        left: 4px;

        &:before {
            transform: rotate(180deg);
        }
    }

    .top-slider {

        .chart {
            padding: 12px 40px;
        }
    }

    .slider-nav {

        .slick {

            &-slide {
                border-top: 4px solid #fff;
                font-size: 16px;
                padding: 16px 0;
                text-align: center;

                &.slick-current {
                    border-color: $color_primary;
                    color: $color_primary;
                }
            }

            &-list {
                margin: 0 auto;
                width: 85%;
            }

            &-slider {
                box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
            }

            &-arrow {
                top: 48%;
            }
        }
    }

    .chart-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .value {
            padding-left: 4px;
        }
    }

    .value {
        color: #4c5862;
        font-size: 12px;
    }

    .label {
        color: #9ea3a8;
        font-size: 12px;
    }
}

.chart-content-list {
    display: flex;
    justify-content: space-between;

    .list-item {
        display: flex;
        flex-direction: column;

        .value {
            font-size: 16px;
        }
    }
}

.chart-details {
    padding: 0 16px 16px;
}

@media (max-width: 480px) {

    .chart-slider-wrapper {
        display: block;
    }
}
