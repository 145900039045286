@import "../../../../assets/scss/variables";

.dropdown {

    &-menu {
        display: none;
        max-width: 282px;
        position: absolute;
        right: 0;
        top: 48px;
        width: 100%;
        z-index: 99;

        &.opened {
            display: block;
            z-index: 99;
            width: 100%;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        }
    }

    &-link {
        appearance: none;
        background-color: #fff;
        border: 0;
        color: #666;
        cursor: pointer;
        padding: 16px;
        text-align: left;
        width: 100%;

        &:hover {
            background-color: #f4f6f9;
        }

        &:focus {
            outline: none;
        }
    }

    li {
        flex-direction: column;
    }
}

.toggle-btn {
    appearance: none;
    background: transparent;
    border: 0;
    color: #4f4f4f;
    font-weight: 500;

    &:focus {
        outline: none;
    }

    &:hover {
        color: $light_text_color;
    }

    img {
        border-radius: 50%;
        height: 16px;
        margin-right: 7px;
        object-fit: cover;
        width: 16px;
    }
}

.badge {
    align-items: center;
    background-color: #d16d4b;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 16px;
    justify-content: center;
    line-height: 1;
    margin-left: 26px;
    width: 16px;
}

.logged-as {
    display: none;
}

@media (max-width: 920px) {

    .logged-as {
        display: inline-flex;
        padding-right: 20px;
    }

    .dropdown {

        &-wrapper {
            background-color: #fff;
            box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
            max-width: 414px;
            padding: 0;
            position: absolute;
            right: -9px;
            top: 32px;
            width: 100vw;
            z-index: 9;
        }

        &-menu {
            box-shadow: none;
            display: block;
            position: static;
        }
    }

    .toggle-btn {
        color: #666;
        font-weight: 400;
        padding: 16px;
    }

    .upgrade-link {
        color: $color_primary;
    }
}