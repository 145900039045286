.toast-message-wrapper {
    background-color: rgba(220, 56, 36, 0.81);
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    padding: 16px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 999;

    .message {
        margin-bottom: 0;
        padding-left: 10px;
    }

    svg {
        height: 22px;
        width: 17px;

        path {
            fill: #fff;
        }
    }

    &.success {
        background-color: rgba(54, 172, 163, 0.81);
    }

    &.warning {
        background-color: rgba(246, 168, 33, 0.81);
    }

    &.error {
        background-color: rgba(220, 56, 36, 0.81);
    }
}
