@import "../../../../../assets/scss/variables";

.input,
input[type=text],
input[type=email],
input[type=password] {
    appearance: none;
    background-color: #fff;
    border: 1px solid #8996a2;
    border-radius: $base_border_radius * 2;
    color: $light_text_color;
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    padding: 8px 16px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus,
    &-webkit-autofill,
    &-webkit-autofill:hover,
    &-webkit-autofill:focus {
        border: 1px solid #8996a2;
        transition: background-color 5000s ease-in-out 0s;
    }

    &-webkit-input-placeholder {
        color: rgba(#637381, 0.75);
    }

    &-moz-placeholder {
        color: rgba(#637381, 0.75);
    }

    &-ms-input-placeholder {
        color: rgba(#637381, 0.75);
    }

    &-moz-placeholder {
        color: rgba(#637381, 0.75);
    }

    &:focus {
        border-color: $color_primary;
    }
}

.input-error {
    border-color: $color_error !important;
    color: $color_error !important;
}

.error-block {
    color: $color_error;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}