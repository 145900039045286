@import "../../../assets/scss/variables";

.container {
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-bottom: 12px;
    padding-left: 32px;
    position: relative;
    user-select: none;

    input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
    }
}

.checkmark {
    background-color: transparent;
    border: 1px solid #8996a2;
    border-radius: 2px;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;

    &:after {
        content: "";
        display: none;
        position: absolute;
    }
}

.container {

    &:hover input ~ .checkmark {
        background-color: #f3f3f3;
    }

    input:checked {

        ~ .checkmark {
            background-color: $color_primary;
            border-color: $color_primary;

            &:after {
                display: block;
            }
        }
    }

    .checkmark {

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7153 1.79992L6.78464 0.866592C6.76916 0.85107 6.75076 0.838756 6.73052 0.830354C6.71027 0.821952 6.68856 0.817627 6.66664 0.817627C6.64471 0.817627 6.62301 0.821952 6.60276 0.830354C6.58251 0.838756 6.56412 0.85107 6.54864 0.866592L2.61797 4.79992C2.60249 4.81545 2.5841 4.82776 2.56385 4.83616C2.5436 4.84456 2.52189 4.84889 2.49997 4.84889C2.47805 4.84889 2.45634 4.84456 2.43609 4.83616C2.41584 4.82776 2.39745 4.81545 2.38197 4.79992L1.4513 3.86659C1.43582 3.85107 1.41743 3.83876 1.39718 3.83035C1.37693 3.82195 1.35523 3.81763 1.3333 3.81763C1.31138 3.81763 1.28967 3.82195 1.26943 3.83035C1.24918 3.83876 1.23079 3.85107 1.2153 3.86659L0.284638 4.79992C0.253392 4.83118 0.23584 4.87356 0.23584 4.91776C0.23584 4.96195 0.253392 5.00434 0.284638 5.03559L2.38197 7.13326C2.39745 7.14878 2.41584 7.16109 2.43609 7.1695C2.45634 7.1779 2.47805 7.18222 2.49997 7.18222C2.52189 7.18222 2.5436 7.1779 2.56385 7.1695C2.5841 7.16109 2.60249 7.14878 2.61797 7.13326L7.7153 2.03326C7.74584 2.0021 7.76295 1.96022 7.76295 1.91659C7.76295 1.87297 7.74584 1.83108 7.7153 1.79992Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            height: 9px;
            left: 3px;
            top: 3px;
            width: 9px;
        }
    }
}
