@import "../../../assets/scss/variables";

.anchor-block {
    background-color: #fff;
    margin: -1px auto 0;
    max-width: 1280px;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0;

    @media (max-width: 767px) {
        overflow-x: scroll;
    }

    >li {
        border-bottom: 4px solid transparent;
        color: $dark_text_color;
        padding: 16px 3.31% 8px;
        width: fit-content;
        margin-bottom: 0;
        list-style-type: none;

        button {
            appearance: none;
            background-color: #fff;
            border: 0;
            color: $dark_text_color;

            &:focus {
                outline: none;
            }
        }

        &.selected {
            border-bottom-color: $color_primary;

            button {
                color: $color_primary;
                text-decoration: none;

            }
        }
    }
}

@media (max-width: 920px) {

    .anchor-block {
        display: none;
    }
}