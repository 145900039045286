@import "../../../assets/scss/variables";

.container {
    border: 1px solid #8996a2;
    border-radius: $base_border_radius * 2;
    color: $light_text_color;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    margin-bottom: 0;
    padding: 14px 35px 8px 16px;
    position: relative;
    user-select: none;

    &:hover {
        border-color: $color_primary;
    }

    &.active {
        border: 2px solid $color_primary;
        color: $color_primary;
    }
}

.container input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkmark {
    border: 1px solid $light_text_color;
    border-radius: 50%;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 13px;
    width: 20px;
}

.container input:checked ~ .checkmark {
    background-color: $color_primary;
    border: 0;
}

.checkmark:after {
    content: "";
    display: none;
    position: absolute;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.07573 8.7728L0.175729 4.8728C-0.0585762 4.63849 -0.0585762 4.25859 0.175729 4.02426L1.02424 3.17573C1.25854 2.9414 1.63846 2.9414 1.87277 3.17573L4.5 5.80294L10.1272 0.175729C10.3615 -0.0585762 10.7415 -0.0585762 10.9758 0.175729L11.8243 1.02426C12.0586 1.25857 12.0586 1.63846 11.8243 1.87279L4.92426 8.77282C4.68994 9.00713 4.31004 9.00713 4.07573 8.7728Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 10px;
    left: 5px;
    top: 6px;
    width: 12px;
}
