@import "../../../assets/scss/variables";

.card {
    align-items: center;
    border-radius: $base-border-radius * 2;
    flex-direction: column;
    max-width: 328px;
    min-height: 430px;
    padding: 32px;
    position: relative;
    text-align: center;

    &-action {
        display: flex;
        justify-content: center;
        margin-top: auto;
        width: 100%;
    }

    &-icon {
        align-items: center;
        background-color: $color-danger-light;
        border-radius: 50%;
        height: 104px;
        justify-content: center;
        margin: 0 auto 24px;
        width: 103px;
    }

    &-title {
        margin-bottom: 19px;
        margin-top: 0;
    }

    &-subtitle {
        color: #979797;
    }

    &-body {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.icon-checked {
    align-items: center;
    background-color: $color-primary-light;
    border-radius: 50%;
    height: 20px;
    justify-content: center;
    left: 19px;
    position: absolute;
    top: 19px;
    width: 20px;

    svg {
        height: 10px;
        width: 13px;
    }
}

@media (max-width: 768px) {

    .card {
        margin: 0 auto 25px;
    }
}

@media (max-width: 480px) {

    .card {
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 12px;
        max-width: 398px;
        min-height: unset;
        padding: 16px 24px 24px;
        width: 95%;

        &-body {
            padding-left: 22px;
            text-align: left;
        }

        &-action {
            justify-content: flex-start;
        }

        .card-title {
            font-size: 18px;
            margin-bottom: 8px;
        }

        .card-subtitle {
            font-size: 16px;
        }
    }
}
