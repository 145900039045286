@import "../../../assets/scss/variables";


.tableresponsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    .table {
        width: 100%;
        max-width: 100%;

        th {
            color: $dark_text_color;
            font-weight: 500;
            padding: 21px 16px 12px;
        }

        td {
            color: #666;
            padding: 14px 16px;
        }

        th,
        td {
            border-bottom: 1px solid #d3d8dd;
        }

        th:last-child,
        td:last-child {
            text-align: right;
        }

        .align-center {
            text-align: center;
        }
    }

}